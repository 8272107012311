import http from "../http-common";
import http2 from "../http-multpart";
import auth from "./authentication";
import axios from "axios";

const baseURL = "https://staging.samortech.com/api";

const getAll = () => {
  return http.get(`${baseURL}/section/?all=True`);
};

const getAllSectionsPagination = (page = 1) => {
  return http.get(`${baseURL}/section/?page=${page}`);
};

const getSection = (id) => {
  return http.get(`${baseURL}/section/${id}/`);
};

const createSection = (data) => {
  return http2.post(`${baseURL}/section/`, { data: data });
  // return http.post(`${baseURL}/section/`, {auth: auth}, {data: data});
};

const updateSection = (id, data) => {
  return http.put(`${baseURL}/section/${id}/`, { data: data });
};

const removeSection = (id) => {
  return http.delete(`${baseURL}/section/${id}/`);
};

const removeAllSection = () => {
  return http.delete(`${baseURL}/section/`);
};

const findByTitleSection = (title) => {
  return http.get(`${baseURL}/section?title=${title}`);
};
const sectionSequnce = (data) => {
  let sequencedata = {
    sections: data,
  };
  console.log("sequencedata", sequencedata);
  return http.post(`${baseURL}/section/order_sections/`, sequencedata);
};
export {
  getAll,
  getAllSectionsPagination,
  getSection,
  createSection,
  updateSection,
  removeSection,
  removeAllSection,
  findByTitleSection,
  sectionSequnce,
  baseURL,
  auth,
};
