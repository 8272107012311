import http from '../http-common';
import auth from './authentication'

const baseURL = "https://staging.samortech.com/api";

const getAllSectionTypes = () => {
    return http.get(`${baseURL}/sectiontype/?limit=100`, );
};

const getAllSectionTypesPagination = (page = 0) => {
    return http.get(`${baseURL}/sectiontype/?limit=10&offset=${page}0`, );
};


const createSectionTypes = data => {
    return http.post(`${baseURL}/sectiontype/`, {  data: data });
};

const updateSectionTypes = (id, data) => {
    return http.put(`${baseURL}/sectiontype/${id}/`, {  data: data });
};

export {
    getAllSectionTypes,
    getAllSectionTypesPagination,
    createSectionTypes,
    updateSectionTypes,
    baseURL,
    auth
};