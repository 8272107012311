import React, { Component } from "react";
import {
  getAllLandingPage,
  removeLandingPage,
} from "../../../services/LandingPage";
import { connect } from "react-redux";
import NotAuthenticatedMsg from "../../NotAuthenticatedMsg";

class AllLandingPage extends Component {
  state = {
    ViewAuthentication: false,
    ChangeAuthentication: true,
    deletAuthentication: true,
  };
  componentDidMount = () => {
    let GuestlandingpagePermissionData =
      this.props.GuestlandingpagePermission.filter(
        (data) => data.name === "guestlandingpage"
      );
    GuestlandingpagePermissionData[0].subPermissions.map((data) => {
      if (data.method === "view" && data.isPermitted) {
        this.setState({ ViewAuthentication: true });
        getAllLandingPage()
          .then((res) => {
            console.log("getLanding", res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (data.method === "change" && data.isPermitted) {
        this.setState({ ChangeAuthentication: false });
      } else if (data.method === "delete" && data.isPermitted) {
        this.setState({ deletAuthentication: false });
      }
    });
  };
  render() {
    return (
      <div>
        {this.state.ViewAuthentication ? (
          <>AllLandingPage</>
        ) : (
          <NotAuthenticatedMsg />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  GuestlandingpagePermission: state.User.permission,
});

export default connect(mapStateToProps)(AllLandingPage);
