import React, { Component } from "react";
import { Table, Image, Button, Modal, Col, Form, Row } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import thumbnail from "../../../images/thumbnail.webp";

import AddBannerv2 from "./AddBannerv2";
import {
  getAllBannerType,
  updateBannerType,
} from "../../../services/serviceBannerType";
import { getAllLanguages } from "../../../services/serviceLanguages";
import {
  removeBannerv2,
  updateBannerV2,
} from "../../../services/serviceBanner";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import NotAuthenticatedMsg from "../../NotAuthenticatedMsg";

class Bannerv2 extends Component {
  state = {
    bannerBackgroundImageView: undefined,
    background_image: "",
    background_image_url: "",
    language: " ",
    action_content: "",
    action_slug: " ",
    content_slug: "",
    uniqueSlug: "",
    action: [],
    languages: [],
    sequence: "",
    isLoading: false,
    editBanner: false,
    ViewAuthentication: false,
    ChangeAuthentication: true,
    deletAuthentication: true,
  };
  editBanner = (banner) => {
    this.toggleBannerEditModal();
    console.log("bannerData: ", banner);
    this.setState({
      // name: banner.name,
      short_url: banner.shortUrl || "",
      background_image_v2_url: banner.backgroundImageUrl || "",
      language: banner.language || "",
      bannerBackgroundImageView: banner.backgroundImageUrl || "",
      action_content: banner.actionContent || "",
      action_slug: banner.action.uniqueSlug || "",
      content_slug: banner.contentSlug || "",
      uniqueSlug: banner.uniqueSlug || "",
      sequence: banner.sequence || "",
      end_datetime: banner.endDatetime || "",
      start_datetime: banner.startDatetime || "",
      is_recurring: banner.isRecurring || "",
      is_active: banner.isActive || "",
      filter: this.props.filter,
      frequency: banner.frequency || "",
    });
    console.log("all banner v2 state", this.state);
  };

  toggleBannerEditModal = () => {
    this.setState({ editBanner: !this.state.editBanner });
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onBannerDeleteHandler = async (id) => {
    await removeBannerv2(id)
      .then((res) => {
        console.log(res);
        toast.success(`Banner deleted successfully!`);
        this.props.removeBanner(id);
      })
      .catch((err) => {
        console.log(`Banner delete error`, err);
        toast.error(`Banner delete failed!`);
      });
  };

  componentDidMount = () => {
    let BannerPermissionData = this.props.BannerPermission.filter(
      (data) => data.name === "show"
    );
    BannerPermissionData[0].subPermissions.map((data) => {
      if (data.method === "view" && data.isPermitted) {
        this.setState({ ViewAuthentication: true });
      } else if (data.method === "change" && data.isPermitted) {
        this.setState({ ChangeAuthentication: false });
      } else if (data.method === "delete" && data.isPermitted) {
        console.log("inside deletee");
        this.setState({ deletAuthentication: false });
      }
    });
  };

  render() {
    const { name, short_url } = this.state;
    let tableData = this.props.banners.map((banner, index) => (
      <tr key={banner.uniqueSlug}>
        <td>{index + 1}</td>
        <td>
          <Image
            src={
              banner.backgroundImageUrl ? banner.backgroundImageUrl : thumbnail
            }
            width="100px"
            thumbnail
          ></Image>
        </td>
        <td>{banner.language || "NA"}</td>
        <td>{banner.actionContent || "NA"}</td>
        <td>{banner.contentSlug || "NA"}</td>
        <td>{banner.sequence || "NA"}</td>
        <td>{banner.action ? banner.action.name : "NA"}</td>
        <td
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="primary"
            onClick={() => this.editBanner(banner)}
            disabled={this.state.ChangeAuthentication}
          >
            Edit
          </Button>{" "}
          &nbsp;
          <Button
            variant="danger"
            onClick={() => {
              if (
                window.confirm(`Are you sure you wish to delete this banner?`)
              )
                this.onBannerDeleteHandler(banner.uniqueSlug);
            }}
            disabled={this.state.deletAuthentication}
          >
            Delete
          </Button>
        </td>
      </tr>
    ));
    return (
      <div>
        {this.state.ViewAuthentication ? (
          <>
            <ToastContainer position="top-right" />
            {/* Edit banner modal */}
            <Modal
              size="xl"
              show={this.state.editBanner}
              onHide={this.toggleBannerEditModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Banner</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AddBannerv2
                  data={this.state}
                  update={true}
                  toggleBannerEditModal={this.toggleBannerEditModal}
                  forRefresh={this.forRefresh}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={this.toggleBannerEditModal}
                >
                  Close
                </Button>
                {/* <Button variant="primary" onClick={this.updateHandler}>Save Changes</Button> */}
              </Modal.Footer>
            </Modal>
            {/* Default page */}
            <Row>
              <Col>
                <h4>Banner List({this.props.totalBanner})</h4>
              </Col>
              <Col>
                <Form.Control
                  as="select"
                  value={this.props.filter}
                  name="filter"
                  onChange={this.props.HandelFilter}
                >
                  <option value="True">Active</option>
                  <option value="False">In Active</option>
                </Form.Control>
              </Col>
            </Row>
            <Table responsive>
              <thead>
                <tr>
                  <th>Sr. no</th>
                  <th>Banner Image</th>
                  <th>Language</th>
                  <th>Episode Name</th>
                  <th>Show Name</th>
                  <th>
                    Sequence<span className="asterisk">*</span>
                  </th>
                  <th>Action</th>
                  <th>Manage</th>
                </tr>
              </thead>
              <tbody>
                {tableData.length === 0 ? (
                  <tr>
                    <td>No data</td>
                    <td>No data</td>
                    <td>No data</td>
                    <td>No data</td>
                    <td>No data</td>
                    <td>No data</td>
                    <td>No data</td>
                  </tr>
                ) : (
                  tableData
                )}
              </tbody>
            </Table>
            <div>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.props.paginationCount}
                onPageChange={this.props.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                forcePage={this.props.currentPage - 1}
                // initialPage={1}
              ></ReactPaginate>
            </div>
          </>
        ) : (
          <NotAuthenticatedMsg />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  BannerPermission: state.User.permission,
});

export default connect(mapStateToProps)(Bannerv2);
