import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// Live Show sections
import rjProfilePage from "./components/LiveBackend/rjProfile/rjProfilePage";
import liveShowPage from "./components/LiveBackend/LiveShowDash/liveShowPage";
import AllLiveShows from "./components/LiveBackend/AllLiveShows/AllLiveShows";
import RjLists from "./components/LiveBackend/RjList/RjList";
import GuestList from "./components/LiveBackend/GuestList/guestList";

import PublicRoute from "./components/routes/PublicRoute";
import PrivateRoute from "./components/routes/PrivateRoute";

import "./App.css";
import Header from "./components/HeaderNav/Header";
import "bootstrap/dist/css/bootstrap.css";
import SidebarNav from "./components/SideBar/sidebar";

import Dashboard from "./components/Pages/Dashboard/Dashboard";
import Login from "./components/LoginPage/Login";

import CreateAd from "./components/Pages/Advertisement/CreateAd";
import AllAdLists from "./components/Pages/Advertisement/AllAdLists";
// import Badges from './components/Pages/Badges';
import AllBadges from "./components/Pages/Badges/AllBadges";
import AddBadges from "./components/Pages/Badges/AddBadges";

// import Platforms from "./components/Pages/Platforms";
import AllPlatforms from "./components/Pages/Platforms/AllPlatforms";
import AddPlatforms from "./components/Pages/Platforms/AddPlatforms";

// import Channel from "./components/Pages/Channel";
import ChannelSheet from "./components/Pages/Channel/ChannelSheet";
import AllChannel from "./components/Pages/Channel/AllChannel";
import AddChannel from "./components/Pages/Channel/AddChannel";

// import Shows from "./components/Pages/Shows";
import SheetUpload from "./components/Pages/Shows/SheetUpload";
import AllShows from "./components/Pages/Shows/AllShows";
import NewShow from "./components/Pages/Shows/NewShow";
// import AddShow from "./components/Pages/Shows/AddShow";
// import EditShow from "./components/Pages/Shows/EditShow";
import EditShowEpisode from "./components/Pages/Shows/EditShowEpisode";

// import Category from "./components/Pages/Category";
import CategorySheet from "./components/Pages/Category/CategorySheet";
import AllCategory from "./components/Pages/Category/AllCategory";
import AddCategory from "./components/Pages/Category/AddCategory";

// import Banners from './components/Pages/Banners';
import AddBanner from "./components/Pages/Banner/AddBanner";
import AllBanner from "./components/Pages/Banner/AllBanner";

// import Section from "./components/Pages/Section";
import AddSection from "./components/Pages/Section/AddSection";
import AllSection from "./components/Pages/Section/AllSection";
import SequeceSection from "./components/Pages/Section/SequeceSection";

import Rss from "./components/Pages/Rss/RssCollection";
import RssList from "./components/Pages/Rss/RssCollection";
import RssShowUpload from "./components/Pages/Rss/RssShowUpload";
import AddBannerv2 from "./components/Pages/Banner/AddBannerv2";

import AllLanguages from "./components/Pages/Language/AllLanguage";
import AddLanguage from "./components/Pages/Language/AddLanguage";

import AddHeader from "./components/Pages/Headers/AddHeader";
import AllHeader from "./components/Pages/Headers/AllHeader";

import CreateLanding from "./components/Pages/LandingPage/CreateLanding";
import AllLandingPage from "./components/Pages/LandingPage/AllLandingPage";

import Addartist from "./components/Pages/Artist/addArtist";
import Allartist from "./components/Pages/Artist/allArtist";
import CustomNotification from "./components/Pages/Notification/Custom";

import CreateGroup from "./components/Pages/Group/CreateGroup";
import AllGroup from "./components/Pages/Group/AllGroup";


import CmsUser from "./components/Pages/CmsUser/CreateUser"
import AllCmsUser from "./components/Pages/CmsUser/AllCmsUser";


function App() {
  return (
    <React.Fragment>
      <Header />
      <div className="container-fluid">
        <Switch>
          <PublicRoute restricted={true} path="/" exact component={Login} />
          <PrivateRoute component={rjProfilePage} path="/rj-profile" exact />
          <PrivateRoute component={liveShowPage} path="/live-show" exact />
          <PrivateRoute component={AllLiveShows} path="/live-show-list" exact />
          <PrivateRoute component={RjLists} path="/rj-list" exact />
          <PrivateRoute component={GuestList} path="/guest-list" exact />
          <div className="row">
            <nav className="col-md-2 d-none d-md-block bg-light sidebar">
              <SidebarNav />
            </nav>
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
              {/* <PublicRoute restricted={true} component={Login} path="/" exact /> */}
              {/* <PublicRoute restricted={true} component={Badges} path="/badges" exact /> */}
              <PrivateRoute component={Dashboard} path="/dashboard" exact />
              {/* <Route path="/login" exact component={Login} /> */}
              {/* <Route path="/dashboard" exact component={Dashboard} /> */}
              <PrivateRoute path="/createAd" exact component={CreateAd} />
              <PrivateRoute path="/AdList" exact component={AllAdLists} />
              {/* <PrivateRoute path="/badges" exact component={Badges} /> */}
              <PrivateRoute path="/badges/all-badges" component={AllBadges} />
              <PrivateRoute path="/badges/new-badges" component={AddBadges} />
              {/* <PrivateRoute path="/platforms" exact component={Platforms} /> */}
              <PrivateRoute
                path="/platforms/all-platforms"
                component={AllPlatforms}
              />
              <PrivateRoute
                path="/platforms/new-platforms"
                component={AddPlatforms}
              />
              {/* <PrivateRoute path="/channel" exact component={Channel} /> */}
              <PrivateRoute
                path="/channel/sheet-upload"
                component={ChannelSheet}
              />
              <PrivateRoute
                path="/channel/new-channel"
                component={AddChannel}
              />
              <PrivateRoute
                path="/channel/all-channel"
                component={AllChannel}
              />
              <PrivateRoute
                path="/channel/edit-channel/:id"
                component={AddChannel}
              />
              {/* <PrivateRoute path="/shows" exact component={Shows} /> */}
              <PrivateRoute
                path="/shows/sheet-upload"
                component={SheetUpload}
              />
              {/* <PrivateRoute path="/shows/add-show" component={AddShow} /> */}
              <PrivateRoute path="/shows/new-show" component={NewShow} />
              <PrivateRoute path="/shows/all-show" component={AllShows} />
              {/* <PrivateRoute path="/shows/edit-show" component={EditShow} /> */}
              <PrivateRoute
                path="/shows/edit-show-episode/:unique/:filter"
                component={EditShowEpisode}
              />
              {/* <PrivateRoute path="/category" exact component={Category} /> */}
              <PrivateRoute
                path="/category/sheet-upload"
                component={CategorySheet}
              />
              <PrivateRoute
                path="/category/new-category"
                component={AddCategory}
              />
              <PrivateRoute
                path="/category/all-category"
                component={AllCategory}
              />
              <PrivateRoute
                path="/category/edit-category/:id"
                component={AddCategory}
              />
              {/* <PrivateRoute path="/banners" exact component={Banners} /> */}
              <PrivateRoute path="/banners/all-banner" component={AllBanner} />
              <PrivateRoute path="/banners/new-banner" component={AddBanner} />
              <PrivateRoute
                path="/banners/new-banner-v2"
                component={AddBannerv2}
              />
              <PrivateRoute path="/rss" exact component={Rss} />
              <PrivateRoute path="/rss-show" exact component={RssList} />
              <PrivateRoute
                path="/rss-import"
                exact
                component={RssShowUpload}
              />
              {/* <PrivateRoute path="/section" exact component={Section} /> */}
              <PrivateRoute
                path="/section/all-section"
                component={AllSection}
              />
              <PrivateRoute
                path="/section/new-section"
                component={AddSection}
              />
              <PrivateRoute
                path="/section/edit-section/:id"
                component={AddSection}
              />
              <PrivateRoute
                path="/section/sequece-section"
                component={SequeceSection}
              />
              <PrivateRoute path="/language" component={AddLanguage} />
              <PrivateRoute path="/all-language" component={AllLanguages} />
              <PrivateRoute path="/header" component={AddHeader} />
              <PrivateRoute path="/all-header" component={AllHeader} />
              <PrivateRoute path="/artist" component={Addartist} />
              <PrivateRoute path="/all-artist" component={Allartist} />
              <PrivateRoute path="/Group" component={CreateGroup} />
              <PrivateRoute path="/all-Group" component={AllGroup} />
              <PrivateRoute path="/Custom" component={CustomNotification} />
              <PrivateRoute path="/LandingPage" component={CreateLanding} />
              <PrivateRoute
                path="/all-LandingPage"
                component={AllLandingPage}
              />
              <PrivateRoute path="/CmsUser" component={CmsUser} />
              <PrivateRoute path="/all-CmsUser" component={AllCmsUser} />
            </main>
          </div>
        </Switch>
      </div>
    </React.Fragment>
  );
}

export default App;
