import { store } from "../Redux/app/store";
import httpCommon from "../http-common";
import httpMultpart from "../http-multpart";

const Token = () => {
  var newState = store.getState();
  if (newState.User.token) {
    httpCommon.defaults.headers.common[
      "Authorization"
    ] = `Token ${newState.User.token}`;
    httpMultpart.defaults.headers.common[
      "Authorization"
    ] = `Token ${newState.User.token}`;
    return newState.User.token;
  } else if (localStorage.getItem("token")) {
    httpCommon.defaults.headers.common[
      "Authorization"
    ] = `Token ${localStorage.getItem("token")}`;
    httpMultpart.defaults.headers.common[
      "Authorization"
    ] = `Token ${localStorage.getItem("token")}`;
    return localStorage.getItem("token");
  }
};

export { Token };
