import React, { Component } from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getShowsDropDowns, baseURL } from "../../../services/serviceShows";
import auth from "../../../services/authentication";
import { createRss, RssShow, UpdateRss } from "../../../services/serviceRss";
import { connect } from "react-redux";
import NotAuthenticatedMsg from "../../NotAuthenticatedMsg";

class RssAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: [],
      show_name: "",
      rss_url: "",
      show_slug: "",
      rss_etag: "",
      AddAuthentication: false,
    };
  }

  componentDidMount() {
    let RssPermission = this.props.RssPermission.filter(
      (data) => data.name === "rssupdates"
    );
    RssPermission[0].subPermissions.map((data) => {
      if (
        (data.method === "add" && data.isPermitted)
      ) {
        this.setState({ AddAuthentication: true });
      }
    });
    if (this.props.data) {
      this.setState({ ...this.props.data });
    }
    getShowsDropDowns()
      .then((response) => {
        this.setState({ show: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "show_slug") {
      let data1 = e.target.value;
      let data2 = this.state.show.find(
        (element) => element.uniqueSlug == data1
      );
      this.setState({ show_name: data2.title });
    }
  };
  handleLink = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    e.preventDefault();
    // console.log(this.state)
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "show":
          break;
        case "show_name":
          if (this.state.show_name === "") {
            this.setState({ show_name: "NA" });
          } else {
            formData.append(`${key}`, value);
          }
          break;
        // case 'rss_etag':
        //   if(this.state.rss_etag == ""){
        //     this.setState({rss_etag : "NA"})
        //   }
        default:
          formData.append(`${key}`, value);
      }
    }
    //   for (var [key, value] of formData.entries()) {
    //     if (value === "" || value === undefined || value === {}) {
    //         return toast.error(`${key} is required!`)
    //     }
    //     console.log(key + ': ' + value);
    // }
    createRss(formData)
      .then((response) => {
        if (response.status === 201) {
          toast.success(` RSS created successfully.`);
          this.setState({ rss_url: "", show_slug: "", rss_etag: "" });
        }
        if (response.status === 400) {
          toast.error("Something went wrong, please try again");
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key} ${value[0]}. `;
          }
          toast.error(err);
        }
        console.log(error);
      });
  };

  editRss = () => {
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "show":
          break;
        case "show_name":
          if (this.state.show_name === "") {
            this.setState({ show_name: "NA" });
          } else {
            formData.append(`${key}`, value);
          }
          break;
        // case 'rss_etag':
        //   if(this.state.rss_etag == ""){
        //     this.setState({rss_etag : "NA"})
        //   }
        default:
          formData.append(`${key}`, value);
      }
    }
    //   for (var [key, value] of formData.entries()) {
    //     if (value === "" || value === undefined || value === {}) {
    //         return toast.error(`${key} is required!`)
    //     }
    //     console.log(key + ': ' + value);
    // }
    UpdateRss(this.state.id, formData)
      .then((response) => {
        if (response.status === 200) {
          toast.success(` RSS update successfully.`);
          this.props.close();
          this.setState({
            show_name: "",
            rss_url: "",
            show_slug: "",
            rss_etag: "",
          });
        }
        if (response.status === 400) {
          toast.error("Something went wrong, please try again");
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key} ${value[0]}. `;
          }
          toast.error(err);
        }
        console.log(error);
      });
  };

  render() {
    return (
      <div>
        {this.state.AddAuthentication || this.props.editRss ? (
          <>
            <ToastContainer
              autoClose={5000}
              hideProgressBar={false}
              position="top-center"
            />
            <Form onSubmit={this.submitHandler}>
              <Form.Group>
                <Form.Row>
                  <Col md={5}>
                    <Form.Label>
                      Rss URL:<span className="asterisk"> *</span>{" "}
                    </Form.Label>{" "}
                    <br></br>
                    <Form.Control
                      type="text"
                      placeholder="Enter Rss URL here"
                      name="rss_url"
                      value={this.state.rss_url}
                      onChange={this.handleLink}
                    />
                  </Col>

                  <Col>
                    <Form.Label>
                      Show name: <span className="asterisk"> *</span>
                    </Form.Label>{" "}
                    <br></br>
                    <Form.Control
                      as="select"
                      name="show_slug"
                      value={this.state.show_slug}
                      onChange={this.handleChange}
                    >
                      <option defaultValue value="">
                        Select a Show
                      </option>
                      {this.state.show.map((show, index) => (
                        <option key={index} value={show.uniqueSlug}>
                          {show.title}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  {/* <Col>
                  <Form.Label>Show name: </Form.Label> <br></br>
                  <Form.Control type="text" placeholder="Show name" name="show_name" value={this.state.show_name} onChange={this.handleChange} />          
                  </Col> */}
                  <Col>
                    <Form.Label>Rss Etag: </Form.Label> <br></br>
                    <Form.Control
                      type="text"
                      placeholder="RssEtag"
                      name="rss_etag"
                      value={this.state.rss_etag}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Form.Row>
              </Form.Group>
              {this.props.editRss === true ? (
                <Button variant="info" onClick={this.editRss}>
                  Update
                </Button>
              ) : (
                <Button type="submit" variant="info">
                  Publish
                </Button>
              )}
            </Form>
          </>
        ) : (
          <NotAuthenticatedMsg />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  RssPermission: state.User.permission,
});

export default connect(mapStateToProps)(RssAdd);
