import http from "../http-common";
import auth from "./authentication";
const baseURL = "https://staging.samortech.com/api";
// let auth ={username: "aawazadmin", password:"aawazadmin"}

const createHeader = (data) => {
  return http.post(`${baseURL}/image-assets/`, data, );
};
const GetAllHeader = () => {
  return http.get(`${baseURL}/image-assets/`, );
};

const UpatdeHeader = (id, data) => {
  return http.patch(`${baseURL}/image-assets/${id}/`, data, );
};
const deleteHeader = (id) => {
  return http.delete(`${baseURL}/image-assets/${id}/`, );
};
export { createHeader, GetAllHeader, UpatdeHeader, deleteHeader };
