import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("token"),
  permission: JSON.parse(localStorage.getItem("permissionData")),
};

export const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    SetLogin: (state, action) => {
      state.token = action.payload.token;
      state.permission = action.payload.Permissions;
    },
  },
});

// Action creators are generated for each case reducer function
export const { SetLogin } = userSlice.actions;
export const selectToken = (state) => state.token;

export default userSlice.reducer;
