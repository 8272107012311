import React, { Component } from "react";
import axios from "axios";
import { Form, Col, Button, Figure } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  baseURL,
  updateChannel,
  getChannel,
} from "../../../services/serviceChannels";
import moment from "moment";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import auth from "../../../services/authentication";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import NotAuthenticatedMsg from "../../NotAuthenticatedMsg";

class AddChannel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      bannerImageView: "",
      featuredImageView: "",
      featured_image: "",
      dateTime: new Date(),
      published_on: `${moment(new Date()).format()}`,
      weight: "",
      sequence: "",
      short_description: "",
      description: "",
      color: "#000000",
      short_url: "",
      banner_image: "",
      banner_image_url: "",
      featured_image_url: "",
      image_url: "",
      isLoading: false,
      pulishDisabled: false,
      AddAuthentication: false,
    };
  }

  handleDateChange = (date) => {
    this.setState({
      published_on: moment(date).format(),
      dateTime: date,
    });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onFeaturedImageChange = (event) => {
    this.setState({ featuredImageView: undefined, featured_image: undefined });
    if (event.target.files[0]) {
      let reader = new FileReader();
      this.setState({ featured_image: event.target.files[0] });
      reader.onload = (e) => {
        this.setState({ featuredImageView: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  onBannerImageChange = (event) => {
    this.setState({ bannerImageView: undefined, banner_image: undefined });
    if (event.target.files[0]) {
      let reader = new FileReader();
      this.setState({ banner_image: event.target.files[0] });
      reader.onload = (e) => {
        this.setState({ bannerImageView: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  submitHandler = (e) => {
    this.setState({ pulishDisabled: true });
    e.preventDefault();
    this.setState({ isLoading: true });
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "weight":
          formData.append(`${key}`, parseInt(value));
          break;
        case "sequence":
          formData.append(`${key}`, parseInt(value));
          break;
        case "bannerImageView":
          break;
        case "featuredImageView":
          break;
        case "dateTime":
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    for (var [key, value] of formData.entries()) {
      if (value === "" || value === undefined || value === {}) {
        this.setState({ isLoading: false });
      }
    }

    axios({
      url: `${baseURL}/channels/`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      auth: auth,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(
            `New Channel ${response.data.title} created successfully.`
          );
          this.setState({
            isLoading: false,
            title: "",
            bannerImageView: "",
            featuredImageView: "",
            featured_image: "",
            weight: "",
            sequence: "",
            short_description: "",
            description: "",
            color: "",
            short_url: "",
            banner_image: "",
            banner_image_url: "",
            featured_image_url: "",
            image_url: "",
            pulishDisabled: false,
          });
        }
        if (response.status === 400) {
          toast.error("Something went wrong, please try again");
          this.setState({ isLoading: false, pulishDisabled: false });
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 400) {
          toast.error(JSON.stringify(error.response.data));
          this.setState({ isLoading: false, pulishDisabled: false });
        }
        console.log(error);
        this.setState({ isLoading: false, pulishDisabled: false });
      });
  };
  componentDidMount = () => {
     let ChannelPermissionData = this.props.ChannelPermission.filter(
       (data) => data.name === "channel"
     );
     ChannelPermissionData[0].subPermissions.map((data) => {
       if (
         (data.method === "add" && data.isPermitted) 
       ) {
         this.setState({ AddAuthentication: true });
       }
     });
    let uniqueSlug = this.props.match.params.id;
    if (uniqueSlug !== "" && uniqueSlug !== undefined) {
      getChannel(uniqueSlug)
        .then((res) => {
          console.log(res.data);
          this.setState({
            isLoading: false,
            bannerImageView: res.data.bannerImageUrl,
            featuredImageView: res.data.featuredImageUrl,
            title: res.data.title,
            weight: res.data.weight,
            sequence: res.data.sequence,
            short_description: res.data.shortDescription,
            description: res.data.description,
            color: res.data.color,
            short_url: res.data.shortUrl,
            banner_image_url: res.data.bannerImageUrl,
            featured_image_url: res.data.featuredImageUrl,
            uniqueSlug: res.data.uniqueSlug,
            isEdit: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  updateHandler = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "weight":
          formData.append(`${key}`, parseInt(value));
          break;
        case "sequence":
          formData.append(`${key}`, parseInt(value));
          break;
        case "displayName":
          break;
        case "titleColor":
          formData.append(`${key}`, value);
          break;
        case "backgroundColor":
          formData.append(`${key}`, value);
          break;
        case "iconImageView":
          break;
        case "featuredImageView":
          break;
        case "bannerImageView":
          break;
        case "bannerImageUrl":
          break;
        case "iconImageUrl":
          break;
        case "featuredImageUrl":
          break;
        case "proxyList":
          break;
        case "shows":
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    for (var [key, value] of formData.entries()) {
      if (value === "" || value === undefined || value === {}) {
        this.setState({ isLoading: false });
      }
    }
    updateChannel(this.state.uniqueSlug, formData)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Channel updated successfully");
          // this.forceUpdate()
          // return this.props.fetchData(this.props.currentPage)
        }
      })
      .catch((error) => {
        if (error.response.status == 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key} ${value[0]}`;
          }
          return toast.error(err);
        } else {
          return toast.error(`channel updated unsuccessful`);
        }
      });
  };
  render() {
    const {
      title,
      weight,
      sequence,
      short_description,
      description,
      color,
      short_url,
    } = this.state;
    return (
      <div>
        {this.state.AddAuthentication || this.state.isEdit ? (
          <>
            <ToastContainer
              autoClose={5000}
              hideProgressBar={false}
              position="top-center"
            />
            {this.state.isEdit ? (
              <h2>Edit Channel</h2>
            ) : (
              <h2>Add New Channel</h2>
            )}

            <Form onSubmit={this.submitHandler}>
              <Form.Group>
                <Form.Row>
                  <Col>
                    <Form.Label>
                      Channel Name: <span className="asterisk"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Channel Name here"
                      name="title"
                      value={title}
                      onChange={this.changeHandler}
                    />
                  </Col>
                </Form.Row>
                <br />
                <Form.Group>
                  <Form.Label>Featured Image: </Form.Label>
                  <br></br>
                  {this.state.featuredImageView ? (
                    <Figure>
                      <Figure.Image
                        src={this.state.featuredImageView}
                        thumbnail
                        width={171}
                        height={180}
                      />
                    </Figure>
                  ) : (
                    <p>No image selected</p>
                  )}
                  <Form.File
                    type="file"
                    name="featured_image"
                    label="featured Image Upload"
                    accept="image/*"
                    onChange={this.onFeaturedImageChange}
                    custom
                  />
                </Form.Group>
                <Form.Label>Featured Image URL: </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Featured Image URL"
                  name="featured_image_url"
                  value={this.state.featured_image_url}
                  onChange={this.changeHandler}
                />
                <br />
                <Form.Label>Image URL: </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Image URL"
                  name="image_url"
                  value={this.state.image_url}
                  onChange={this.changeHandler}
                />{" "}
                <br />
                <Form.Group>
                  <Form.Row>
                    <Col>
                      <Form.Label>Banner Image: </Form.Label>
                      <br></br>
                      {this.state.bannerImageView ? (
                        <Figure>
                          <Figure.Image
                            src={this.state.bannerImageView}
                            thumbnail
                            width={171}
                            height={180}
                          />
                        </Figure>
                      ) : (
                        <p>No image selected</p>
                      )}
                      <Form.File
                        type="file"
                        name="banner_image"
                        label="banner Image Upload"
                        accept="image/*"
                        onChange={this.onBannerImageChange}
                        custom
                      />
                    </Col>
                  </Form.Row>
                  <br />
                  <Form.Label>Banner Image URL: </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Banner Image URL"
                    name="banner_image_url"
                    value={this.state.banner_image_url}
                    onChange={this.changeHandler}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Published On: </Form.Label>
                  <DatePicker
                    name="published_on"
                    selected={this.state.dateTime}
                    onChange={this.handleDateChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                  {}
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Row>
                    <Col>
                      <Form.Label>
                        Weight: <span className="asterisk"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Weight"
                        min="1"
                        name="weight"
                        value={weight}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Col>
                      <Form.Label>
                        sequence: <span className="asterisk"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="sequence"
                        min="1"
                        name="sequence"
                        value={sequence}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Color: </Form.Label>
                      <Form.Control
                        type="color"
                        placeholder="color"
                        name="color"
                        value={color}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </Form.Row>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Description: <span className="asterisk"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    name="description"
                    value={description}
                    onChange={this.changeHandler}
                  />
                </Form.Group>
              </Form.Group>
              <Form.Group>
                <Form.Label>Short Description: </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Short Description"
                  name="short_description"
                  value={short_description}
                  onChange={this.changeHandler}
                />
              </Form.Group>
              <Form.Group>
                <Form.Row>
                  <Col>
                    <Form.Label>
                      Short URL: <span className="asterisk"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Short URL"
                      name="short_url"
                      value={short_url}
                      onChange={this.changeHandler}
                    />
                  </Col>
                </Form.Row>
              </Form.Group>
              <Form.Group></Form.Group>
              {this.state.isEdit ? (
                <Button onClick={this.updateHandler} variant="info">
                  Update
                </Button>
              ) : (
                <Button
                  type="submit"
                  disabled={this.state.pulishDisabled}
                  variant="info"
                >
                  Publish
                </Button>
              )}
            </Form>
          </>
        ) : (
          <NotAuthenticatedMsg />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  ChannelPermission: state.User.permission,
});

export default connect(mapStateToProps)(AddChannel);
