import http from "../http-common";
const baseURL = "https://staging.samortech.com/api";

const getAllGroup = () => {
  return http.get(`${baseURL}/groups/`);
};

const createGroup = (data) => {
  return http.post(`${baseURL}/groups/`, data);
};

const updateGroup = (id, data) => {
  return http.patch(`${baseURL}/groups/${id}/`, data);
};
const GetGroupById = (id) => {
  return http.get(`${baseURL}/groups/${id}/`);
};
const removeGroup = (id) => {
  return http.delete(`${baseURL}/groups/${id}/`);
};

const getPermission = () => {
  return http.get(`${baseURL}/permission/`);
};

export {
  getAllGroup,
  createGroup,
  updateGroup,
  removeGroup,
  getPermission,
  GetGroupById,
};
