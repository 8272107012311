import http from '../http-common';
import auth from './authentication';
const baseURL = "https://staging.samortech.com";

const CreateNotification = (data) => {
    return http.post(`${baseURL}/api/custom-notification/`, data);
}
const ScheduleNotification = (data) => {
    return http.post(`${baseURL}/api/schedule-task/`, data);
}


export {
    CreateNotification, ScheduleNotification
};