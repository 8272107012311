import http from "../http-common";
import auth from "./authentication";

const baseURL = "https://staging.samortech.com/api";

const getAllBannerType = () => {
  return http.get(`${baseURL}/banner-actions/?limit=200`);
};

const getBannerType = (id) => {
  return http.get(`${baseURL}/banner-actions/${id}/`);
};

const createBannerType = (data) => {
  return http.post(`${baseURL}/banner-actions/`, { data: data });
};

const updateBannerType = (id, data) => {
  return http.patch(`${baseURL}/banner-actions/${id}/`, { data: data });
};

const removeBannerType = (id) => {
  return http.delete(`${baseURL}/banner-actions/${id}`);
};

export {
  getAllBannerType,
  getBannerType,
  createBannerType,
  updateBannerType,
  removeBannerType,
  baseURL,
};
