import http from "../http-common";
import auth from "./authentication";

const baseURL = "https://staging.samortech.com/api";

const getAllShow = () => {
  return http.get(`${baseURL}/shows/?all=True`);
};

const getShowsDropDowns = (filter) => {
  if (filter !== "" && filter !== undefined) {
    return http.get(
      `${baseURL}/shows/?all=True&is_active=${filter}&only_select=True`
    );
  } else {
    return http.get(`${baseURL}/shows/?only_select=True&all=True`);
  }
};

const getAllShowPaginate = (page = 1) => {
  return http.get(`${baseURL}/shows/?page=${page}`);
};

const getShow = (id, filter) => {
  if (filter === "False") {
    return http.get(`${baseURL}/shows/${id}/?is_active=${filter}`);
  } else {
    return http.get(`${baseURL}/shows/${id}`);
  }
};

const createShow = (data) => {
  return http.post(`${baseURL}/shows/`, { data: data });
};

const updateShow = (id, data) => {
  return http.patch(`${baseURL}/shows/${id}/`, { data: data });
};

const removeShow = (id) => {
  return http.delete(`${baseURL}/shows/${id}`);
};

const removeAllShow = () => {
  return http.delete(`${baseURL}/shows`);
};

const findByTitleShow = (title) => {
  return http.get(`${baseURL}/shows?title=${title}`);
};

export {
  getAllShow,
  getAllShowPaginate,
  getShow,
  createShow,
  updateShow,
  removeShow,
  removeAllShow,
  findByTitleShow,
  getShowsDropDowns,
  baseURL,
};
