import http from "../http-common";
import auth from "./authentication";
const baseURL = "https://staging.samortech.com";

//  RJ apis
const AddRj = (data) => {
  return http.post(`${baseURL}/live_shows/rjs/`, data);
};
const RjLogin = (data) => {
  return http.post(`${baseURL}/live_shows/login/`, data);
};
const ListRj = (page = 1) => {
  if (page === 1) {
    return http.get(`${baseURL}/live_shows/rjs/`);
  } else {
    return http.get(`${baseURL}/live_shows/rjs/?limit=10&offset=${page - 1}0`);
  }
};
const AllListRj = () => {
  return http.get(`${baseURL}/live_shows/rjs/?all=True`);
};
const UpdateRj = (id, data) => {
  return http.patch(`${baseURL}/live_shows/rjs/rj-${id}/`, data);
};
const deleteRj = (id) => {
  return http.delete(`${baseURL}/live_shows/rjs/${id}/`);
};

//  Live shows apis
const AddLiveShow = (data) => {
  return http.post(`${baseURL}/live_shows/shows/`, data);
};
const allLiveShowDropdown = () => {
  return http.get(`${baseURL}/live_shows/shows/?all=True&only_select=True`);
};
const LiveShowDashboard = () => {
  return http.get(`${baseURL}/live_shows/dashboard/`);
};
const LiveShowList = (page = 1) => {
  if (page === 1) {
    return http.get(`${baseURL}/live_shows/shows/`);
  } else {
    return http.get(
      `${baseURL}/live_shows/shows/?limit=10&offset=${page - 1}0`
    );
  }
};
const UpdateLiveShow = (id, data) => {
  return http.patch(`${baseURL}/live_shows/shows/${id}/`, data);
};
const DeleteLiveShow = (id) => {
  return http.delete(`${baseURL}/live_shows/shows/${id}/`);
};
//    Guest apis

const createGuest = (data) => {
  return http.post(`${baseURL}/live_shows/guests/`, data);
};
const guestList = () => {
  return http.get(`${baseURL}/live_shows/guests/`);
};
const AllGuestList = () => {
  return http.get(`${baseURL}/live_shows/guests/?all=True`);
};
const UpdateGuest = (id, data) => {
  return http.patch(`${baseURL}/live_shows/guests/guest-${id}/`, data);
};
export {
  AddRj,
  ListRj,
  UpdateRj,
  deleteRj,
  AddLiveShow,
  LiveShowDashboard,
  LiveShowList,
  RjLogin,
  UpdateLiveShow,
  DeleteLiveShow,
  allLiveShowDropdown,
  createGuest,
  AllGuestList,
  AllListRj,
  guestList,
  UpdateGuest,
  baseURL,
};
