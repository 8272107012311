import React, { Component } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import NotAuthenticatedMsg from "../../NotAuthenticatedMsg";
import { getPermission, getAllGroup } from "../../../services/serviceGroup";
import Select from "react-select";
import { createCmsUser, updateCmsUser } from "../../../services/serviceCmsUser";

class CreateUser extends Component {
  state = {
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    email: "",
    group_id: "",
    permissions_id_list: "",
    field_perms_id_list: "",
    GroupList: [],
    Permissions: [],
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidMount = () => {
    let UserPermissionData = this.props.UserPermission.filter(
      (data) => data.name === "category"
    );
    UserPermissionData[0].subPermissions.map((data) => {
      if (data.method === "add" && data.isPermitted) {
        this.setState({ AddAuthentication: true });
      }
    });
    if (this.props.update) {
      this.setState({
        firstName: this.props.editedData.user.firstName,
        lastName: this.props.editedData.user.lastName,
        email: this.props.editedData.user.email,
        group_id: this.props.editedData.groups[0].id,
      });
      let newPermission = [];
      this.props.editedData.userPermissions.forEach((data) => {
        newPermission.push({ value: data.id, label: data.name });
      });
      this.setState({ permissions_id_list: newPermission });
    }
    getAllGroup()
      .then((res) => {
        this.setState({ GroupList: res.data.results });
      })
      .catch((err) => {
        console.log(err);
      });
    getPermission()
      .then((res) => {
        let newPermission = [];
        res.data.forEach((data) => {
          newPermission.push({ value: data.id, label: data.name });
        });
        this.setState({ Permissions: newPermission });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  selectHandler = (event, value) => {
    this.setState({ permissions_id_list: event });
  };

  //  CREATE USER HANDEL
  submitHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "field_perms_id_list":
        case "GroupList":
        case "Permissions":
          break;
        case "firstName":
          formData.append(`${key}`, value);
          break;
        case "lastName":
          formData.append(`${key}`, value);
          break;
        case "username":
          formData.append(`${key}`, value);
          break;
        case "password":
          formData.append(`${key}`, value);
          break;
        case "email":
          formData.append(`${key}`, value);
          break;
          break;
        case "group_id":
          formData.append(`${key}`, value);
          break;
        case "permissions_id_list":
          if (this.state.permissions_id_list != "") {
            let permissionData = this.state.permissions_id_list;

            let val1 = permissionData.map((data) => {
              return data.value;
            });

            let val = val1.toString();
            formData.append(`${key}`, val);
          }
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    createCmsUser(formData)
      .then((res) => {
        this.setState({
          name: "",
          permissions_id_list: "",
          level: "",
        });
        toast.success(`user  ${res.data.name} create sucessfully!`);
      })
      .catch((error) => {
        let err = "";
        for (let [key, value] of Object.entries(error.response.data)) {
          err = `${key} :${value}. `;
        }
        toast.error(err);

        console.log(err);
      });
  };

  // UPDATE USER HANDEL
  Updatehandeler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "field_perms_id_list":
        case "GroupList":
        case "Permissions":
          break;
        case "firstName":
          formData.append(`${key}`, value);
          break;
        case "lastName":
          formData.append(`${key}`, value);
          break;
        case "username":
          formData.append(`${key}`, value);
          break;
        case "password":
          formData.append(`${key}`, value);
          break;
        case "email":
          formData.append(`${key}`, value);
          break;
          break;
        case "group_id":
          formData.append(`${key}`, value);
          break;
        case "permissions_id_list":
          if (this.state.permissions_id_list != "") {
            let permissionData = this.state.permissions_id_list;

            let val1 = permissionData.map((data) => {
              return data.value;
            });

            let val = val1.toString();
            formData.append(`${key}`, val);
          }
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    updateCmsUser(this.props.editedData.uniqueSlug, formData)
      .then((response) => {
        if (response.status === 200) {
          return toast.success("Cms user updated successfully");
        } else {
          return toast.error(
            "Cms User updated unsuccessful, please try again later!"
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key} ${value[0]}`;
          }
          return toast.error(err);
        } else {
          return toast.error(`Cms User updated unsuccessful`);
        }
      });
  };

  render() {
    return (
      <div>
        <ToastContainer
          autoClose={5000}
          hideProgressBar={false}
          position="top-center"
        />
        <Container>
          {this.state.AddAuthentication || this.props.update ? (
            <Row>
              {this.props.update ? "" : <h1>{`Create Cms User`}</h1>}

              <Col className="centerAlign">
                <Form className="profile-form" onSubmit={this.submitHandler}>
                  {/* FIRST NAME */}

                  <Form.Group controlId="formBasicText">
                    <Form.Control
                      className="login-input"
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={this.state.firstName}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                  {/* Lat Name */}
                  <Form.Group controlId="formBasicText">
                    <Form.Control
                      className="login-input"
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={this.state.lastName}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                  {/* USER NAME */}
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      className="login-input"
                      type="text"
                      placeholder="User Name"
                      name="username"
                      value={this.state.username}
                      onChange={this.handleChange}
                      required={true}
                    />
                  </Form.Group>
                  {/* password */}
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      className="login-input"
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      required={true}
                    />
                  </Form.Group>
                  {/* Permission Group */}
                  <Form.Row>
                    <Form.Label>Permissions:</Form.Label>

                    <Col>
                      <Select
                        isMulti
                        defaultValue
                        value={this.state.permissions_id_list}
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            width: 300,
                          }),
                        }}
                        name="permissions_id_list"
                        onChange={this.selectHandler}
                        // isLoading={this.state.isShowLoading}
                        options={this.state.Permissions}
                      />
                    </Col>
                    {/* <Col>
              <Form.Control
                as="select"
                name="permissions_id_list"
                value={this.state.permissions_id_list}
                onChange={this.changeHandler}
              >
                <option defaultValue value="">
                  Select Action
                </option>
                {this.state.Permissions.map((data, index) => (
                  <option key={index} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </Form.Control>
            </Col> */}
                    <Col>
                      <Form.Label>Group Id:</Form.Label>
                      <Form.Control
                        as="select"
                        name="group_id"
                        value={this.state.group_id}
                        onChange={this.handleChange}
                      >
                        <option defaultValue value="">
                          Select Level
                        </option>
                        {this.state.GroupList.map((data, index) => (
                          <option key={index} value={data.id}>
                            {data.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Row>
                  {/* email */}
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control
                      className="login-input"
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      required={true}
                    />
                  </Form.Group>

                  <div style={{ marginTop: "12px", width: "200px" }}>
                    {this.props.update ? (
                      <Button
                        className="login-button"
                        variant="outline-light"
                        onClick={this.Updatehandeler}
                      >
                        Update Profile
                      </Button>
                    ) : (
                      <Button
                        className="login-button"
                        variant="outline-light"
                        type="submit"
                      >
                        Save Profile
                      </Button>
                    )}
                  </div>
                </Form>
              </Col>
            </Row>
          ) : (
            <NotAuthenticatedMsg />
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  UserPermission: state.User.permission,
});

export default connect(mapStateToProps)(CreateUser);
