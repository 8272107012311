import React, { Component } from "react";
import {
  createLandingPage,
  updateLandingPage,
} from "../../../services/LandingPage";
import { connect } from "react-redux";
import NotAuthenticatedMsg from "../../NotAuthenticatedMsg";

class CreateLanding extends Component {
  state = {
    AddAuthentication: false,
  };
  componentDidMount = () => {
    let GuestlandingpagePermission =
      this.props.GuestlandingpagePermission.filter(
        (data) => data.name === "rssupdates"
      );
    GuestlandingpagePermission[0].subPermissions.map((data) => {
      if (data.method === "add" && data.isPermitted) {
        this.setState({ AddAuthentication: true });
      }
    });
  };
  render() {
    return (
      <div>
        {this.state.AddAuthentication ? (
          <>CreateLanding</>
        ) : (
          <NotAuthenticatedMsg />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  GuestlandingpagePermission: state.User.permission,
});

export default connect(mapStateToProps)(CreateLanding);
