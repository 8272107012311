import React, { Component } from "react";
import { Form, Col, Button, Figure, Container, Row } from "react-bootstrap";
import {
  CreateAd,
  Adlist,
  AllAdList,
  UpdateAd,
  deleteAd,
} from "../../../services/serviceAds";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { FormDropdown } from "semantic-ui-react";
import { getShowsDropDowns } from "../../../services/serviceShows";
import ContentLoader from "react-content-loader";

class CreateAds extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adTitle: "",
      adDetails: "",
      adPosition: "",
      adStartDate: new Date(),
      adEndDate: new Date(),
      showSlug: "",
      adTargetUrl: "",
      adTargetType: "",
      image: "",
      image_url: "",
      audioFile: "",
      ImageView: undefined,
      isLoading: false,
      showdata: [],
    };
  }

  componentDidMount = () => {
    getShowsDropDowns()
      .then((response) => {
        this.setState({ showdata: response.data, isLoading: false });
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    if (this.props.editedData) {
      this.setState({
        adStartDate: new Date(this.props.editedData.adStartDate),
        adEndDate: new Date(this.props.editedData.adEndDate),
        adTitle: this.props.editedData.adTitle,
        adDetails: this.props.editedData.adDetails,
        adPosition: this.props.editedData.adPosition,
        adTargetUrl: this.props.editedData.adTargetUrl,
        adTargetType: this.props.editedData.adTargetType,
        showSlug: this.props.editedData.showSlug,
        image_url: this.props.editedData.imageUrl,
        uniqueSlug: this.props.editedData.uniqueSlug,
      });
    }
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onBannerBackgroundImageChange = (event) => {
    // this.setState({ ImageView: undefined, image: '' })
    if (event.target.files[0]) {
      let reader = new FileReader();
      // if(event.target.files[0].type == "image/jpeg"){
      this.setState({ image: event.target.files[0] });
      console.log("image", event.target.files[0].type);

      // }else{
      //     toast.error(`Invalid File format. The file formats supported are  jpg only`)
      // }

      reader.onload = (e) => {
        this.setState({ ImageView: e.target.result });
        console.log("image result", e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  onAudioFileChange = (event) => {
    this.setState({ audioFile: event.target.files[0] });
  };

  submitHandler = (e) => {
    e.preventDefault();
    // console.log(this.state)
    this.setState({ isLoading: true });
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "ImageView":
        case "showdata":
        case "adEndDate":
          if (this.state.adEndDate != "") {
            formData.append(
              `${key}`,
              this.state.adEndDate.toISOString().slice(0, 10)
            );
          }
          break;
        case "adStartDate":
          if (this.state.adStartDate != "") {
            formData.append(
              `${key}`,
              this.state.adStartDate.toISOString().slice(0, 10)
            );
          }
          break;
        default:
          formData.append(`${key}`, value);
      }
    }

    CreateAd(formData)
      .then((response) => {
        if (response.status === 201) {
          toast.success(`New Ad is created successfully.`);
          this.setState({
            adTitle: "",
            adDetails: "",
            adPosition: "",
            adTargetUrl: "",
            image: "",
            image_url: "",
            ImageView: undefined,
            isLoading: false,
          });
          // window.location.reload(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key} ${value[0]}. `;
          }
          toast.error(err);
          this.setState({ isLoading: false });
        }
        console.log(error);
        this.setState({ isLoading: false });
      });
  };
  updateHandler = (e) => {
    e.preventDefault();
    this.props.toggleAdListEditModal();
    console.log("updateHanderler", this.state);
    this.setState({ isLoading: true });
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "ImageView":
        case "isLoading":
        case "showdata":
        case "image":
          if (this.state.image != "") {
            formData.append("image", value);
          }

          break;
        case "adEndDate":
          if (this.state.adEndDate != "") {
            formData.append(
              `${key}`,
              this.state.adEndDate.toISOString().slice(0, 10)
            );
          }
          break;
        case "adStartDate":
          if (this.state.adStartDate != "") {
            formData.append(
              `${key}`,
              this.state.adStartDate.toISOString().slice(0, 10)
            );
          }
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    for (var [key, value] of formData.entries()) {
      if (value === "" || value === undefined || value === {}) {
        this.setState({ isLoading: false });
      }
      //   console.log(formData);
    }
    //   const data1= {language:"ur"}
    UpdateAd(this.state.uniqueSlug, formData)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            adTitle: "",
            adDetails: "",
            adPosition: "",
            adTargetUrl: "",
            image: "",
            image_url: "",
            ImageView: undefined,
            isLoading: false,
          });
          return toast.success(
            `Ad ${response.data.adTitle}updated successfully`
          );
        }
      })
      .catch(() => {
        toast.error("Update failed, try again later");
      });
  };

  handleEndDateChange = (date) => {
    this.setState({
      adEndDate: date,
    });
  };
  handleStartDateChange = (date) => {
    this.setState({
      adStartDate: date,
    });
  };
  render() {
    const {
      image_url,
      adTitle,
      adDetails,
      adPosition,
      audioFile,
      adTargetUrl,
      adTargetType,
      showSlug,
      showdata,
    } = this.state;
    const loader = (
      <ContentLoader backgroundColor="#c2c2c2">
        <rect x="0" y="56" rx="3" ry="3" width="150" height="4" />
        <rect x="0" y="72" rx="3" ry="3" width="100" height="4" />
      </ContentLoader>
    );
    return (
      <Container fluid>
        <Row>
          <Col>
            <ToastContainer position="top-center" />
            <h2 style={{ textAlign: "center" }}>Create New Ad</h2>
            {!this.state.isLoading ? (
              <Form onSubmit={this.submitHandler}>
                <Form.Group>
                  {/* Title */}
                  <Form.Row>
                    <Form.Label>
                      adTitle: <span className="asterisk"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ad Title"
                      name="adTitle"
                      value={adTitle}
                      onChange={this.changeHandler}
                      required
                    />
                  </Form.Row>
                  {/* discription */}
                  <Form.Row>
                    <Form.Label>adDetails:</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="4"
                      placeholder="adDetails"
                      name="adDetails"
                      value={adDetails}
                      onChange={this.changeHandler}
                    />
                  </Form.Row>
                  {/* Ad type */}
                  <Form.Row>
                    <Col>
                      <Form.Label>
                        AD Position: <span className="asterisk"> *</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="adPosition"
                        value={adPosition}
                        onChange={this.changeHandler}
                        required
                      >
                        <option defaultValue value="">
                          Select a Ad Type
                        </option>
                        <option defaultValue value="pre">
                          pre
                        </option>
                        <option defaultValue value="post">
                          post
                        </option>
                      </Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>
                        Ad Target Type: <span className="asterisk"> *</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="adTargetType"
                        value={adTargetType}
                        onChange={this.changeHandler}
                        required
                      >
                        <option defaultValue value="">
                          Select a Ad Target Type
                        </option>
                        <option defaultValue value="internal">
                          internal
                        </option>
                        <option defaultValue value="external">
                          external
                        </option>
                      </Form.Control>
                    </Col>
                  </Form.Row>
                  <Form.Group>
                    <Form.Row>
                      <Col>
                        <Form.Label>
                          AD Start Date
                          <span style={{ color: "red" }}>*</span>{" "}
                        </Form.Label>
                        <br></br>
                        <DatePicker
                          name="adStartDate"
                          dateFormat="yyyy/MM/dd"
                          selected={this.state.adStartDate}
                          onChange={(date) => this.handleStartDateChange(date)}
                          required
                        />
                      </Col>
                      <Col>
                        <Form.Label>
                          AD End Date
                          <span style={{ color: "red" }}>*</span>{" "}
                        </Form.Label>
                        <br></br>
                        <DatePicker
                          name="adEndDate"
                          dateFormat="yyyy/MM/dd"
                          selected={this.state.adEndDate}
                          onChange={(date) => this.handleEndDateChange(date)}
                          required
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>

                  <Form.Row>
                    <Col>
                      <Form.Label>Image Upload:</Form.Label>
                      <br></br>
                      {this.state.ImageView ? (
                        <Figure>
                          <Figure.Image
                            src={this.state.ImageView}
                            thumbnail
                            width={171}
                            height={180}
                          />
                        </Figure>
                      ) : (
                        <p>No image selected</p>
                      )}
                      <Form.File
                        name="image"
                        label="Background Image Upload"
                        accept="image/*"
                        onChange={this.onBannerBackgroundImageChange}
                        custom
                      />
                    </Col>
                  </Form.Row>

                  {/* Image url
                <Form.Row>
                  <Form.Label>Image URL:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=" Image URL"
                    name="image_url"
                    value={image_url}
                    onChange={this.changeHandler}
                  />
                </Form.Row> */}
                  <Form.Row>
                    <Col>
                      <Form.Label>
                        Audio File<span style={{ color: "red" }}>*</span>{" "}
                      </Form.Label>
                      <br></br>
                      <Form.File
                        name="audioFile"
                        onChange={this.onAudioFileChange}
                        accept=".mp3"
                        required
                      />
                      <Form.Text className="text-muted">
                        "Supported file type: Only .mp3 .wav files are
                        supported"
                      </Form.Text>
                    </Col>
                    <Col>
                      <Form.Label>
                        Show <span style={{ color: "red" }}>*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="showSlug"
                        value={showSlug}
                        onChange={this.changeHandler}
                        required
                      >
                        <option defaultValue value="">
                          Select show
                        </option>

                        {showdata.map((data, index) => (
                          <option key={index} value={data.uniqueSlug}>
                            {data.title}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Row>
                  {/* Target Url */}
                  <Form.Row>
                    <Form.Label>Target URL:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Target URL"
                      name="adTargetUrl"
                      value={adTargetUrl}
                      onChange={this.changeHandler}
                    />
                  </Form.Row>
                </Form.Group>

                {this.props.update ? (
                  <Button variant="primary" onClick={this.updateHandler}>
                    update Ad
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    disabled={this.state.pulishDisabled}
                    variant="info"
                  >
                    Publish
                  </Button>
                )}
              </Form>
            ) : (
              <tr>
                <td>{loader}</td>
                <td>{loader}</td>
                <td>{loader}</td>
                <td>{loader}</td>
                <td>{loader}</td>
                <td>{loader}</td>
                <td>{loader}</td>
              </tr>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CreateAds;
