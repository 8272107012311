import React, { Component } from "react";
import axios from "axios";
import { Form, Button, Figure } from "react-bootstrap";
import { baseURL } from "../../../services/serviceBadges";
// import moment from 'moment';
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createArtist, updateArtist } from "../../../services/serviceArtists";
import auth from "../../../services/authentication";
import { connect } from "react-redux";
import NotAuthenticatedMsg from "../../NotAuthenticatedMsg";

class AddArtist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bio: "",
      email: "",
      name: "",
      picUrl: "",
      shortUrl: "",
      tagLine: "",
      ImageView: "",
      pic: undefined,
      AddAuthentication: false,
    };
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  submitHandler = (e) => {
    e.preventDefault();
    // console.log(this.state)
    this.setState({ isLoading: true });
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "pic":
          if (this.state.pic === undefined) {
            formData.append(`${key}`, "");
          } else {
            formData.append(`${key}`, value);
          }
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    createArtist(formData)
      .then((response) => {
        this.setState({ isLoading: false });
        if (response.status === 201 || response.status === 201) {
          toast.success(
            `New artist ${response.data.name} is created successfully.`
          );
          // window.location.reload(false);
          this.setState({
            bio: "",
            email: "",
            // followersCount: 0,
            name: "",
            picUrl: "",
            shortUrl: "",
            tagLine: "",
            ImageView: "",
            pic: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 400) {
          toast.error(JSON.stringify(error.response.data));
          this.setState({ isLoading: false });
        }
        console.log(error);
      });
  };
  updateHandler = (e) => {
    this.props.handleClose();

    updateArtist(this.state.uniqueSlug, this.state)
      .then((response) => {
        if (response.status === 200) {
          toast.success(`${this.state.name} updated successfully`);
          this.props.fetchArtistsData(1);
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 400) {
          toast.error(JSON.stringify(error.response.data));
          this.setState({ isLoading: false });
        }
        console.log(error);
      });
  };
  componentDidMount = () => {
    let ArtistPermissionData = this.props.ArtistPermission.filter(
      (data) => data.name === "artist"
    );
    console.log("ArtistPermissionData", ArtistPermissionData);
    ArtistPermissionData[0].subPermissions.map((data) => {
      if (data.method === "add" && data.isPermitted) {
        this.setState({ AddAuthentication: true });
      }
    });
    if (this.props.editedArtist) {
      this.setState({ ...this.props.editedArtist });
    }
  };
  ImageChange = (event) => {
    this.setState({ ImageView: undefined, pic: undefined });
    if (event.target.files[0]) {
      let reader = new FileReader();
      this.setState({ pic: event.target.files[0] });
      reader.onload = (e) => {
        this.setState({ ImageView: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  render() {
    const { bio, email, followersCount, name, picUrl, shortUrl, tagLine } =
      this.state;
    return (
      <div>
        {this.state.AddAuthentication || this.props.editedArtist ? (
          <>
            <ToastContainer
              autoClose={5000}
              hideProgressBar={false}
              position="top-center"
            />

            {!this.state.isLoading ? (
              <>
                <h2>Add New Artist</h2>
                <Form onSubmit={this.submitHandler}>
                  <Form.Group>
                    {this.state.ImageView ? (
                      <Figure>
                        <Figure.Image
                          src={this.state.ImageView || this.state.picUrl}
                          thumbnail
                          className="featureImage"
                        />
                      </Figure>
                    ) : null}
                    <Form.File
                      type="file"
                      name="pic"
                      label="Image Upload"
                      accept="image/*"
                      custom
                      onChange={this.ImageChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      Bio: <span className="asterisk"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter bio here"
                      name="bio"
                      value={bio}
                      onChange={this.changeHandler}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      Email: <span className="asterisk"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email here"
                      name="email"
                      value={email}
                      onChange={this.changeHandler}
                    />
                  </Form.Group>
                  {/* <Form.Group>
                        <Form.Label>FollowersCount: <span className="asterisk"> *</span></Form.Label>
                        <Form.Control type="number" name="followersCount" value={followersCount} onChange={this.changeHandler} />
                    </Form.Group> */}
                  <Form.Group>
                    <Form.Label>
                      Artist Name: <span className="asterisk"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Artist Name here"
                      name="name"
                      value={name}
                      onChange={this.changeHandler}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      Pic Url: <span className="asterisk"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="picUrl"
                      name="picUrl"
                      value={picUrl}
                      onChange={this.changeHandler}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      Short Url: <span className="asterisk"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="short Url"
                      name="shortUrl"
                      value={shortUrl}
                      onChange={this.changeHandler}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      TagLine: <span className="asterisk"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="TagLine"
                      name="tagLine"
                      value={tagLine}
                      onChange={this.changeHandler}
                    />
                  </Form.Group>
                  {this.props.editedArtist ? (
                    <Button onClick={() => this.updateHandler()} variant="info">
                      update
                    </Button>
                  ) : (
                    <Button type="submit" variant="info">
                      Publish
                    </Button>
                  )}
                </Form>
              </>
            ) : (
              <Loader
                type="ThreeDots"
                color="#eb1163"
                height={100}
                width={50}
              />
            )}
          </>
        ) : (
          <NotAuthenticatedMsg />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  ArtistPermission: state.User.permission,
});

export default connect(mapStateToProps)(AddArtist);
