import React, { Component } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  RssList,
  UpdateRss,
  deleteRss,
  LiveRss,
} from "../../../services/serviceRss";
import RssAdd from "./RssShowUpload";
import ReactPaginate from "react-paginate";
import ContentLoader from "react-content-loader";
import { connect } from "react-redux";
import NotAuthenticatedMsg from "../../NotAuthenticatedMsg";

class RssCollection extends Component {
  state = {
    next: "not null",
    totalBanner: 0,
    paginationCount: 0,
    currentPage: 1,
    isLoading: true,
    rss: [],
    editRss: false,
    ViewAuthentication: false,
    ChangeAuthentication: true,
    deletAuthentication: true,
  };

  fetchRss(page) {
    RssList(page)
      .then((response) =>
        this.setState({
          rss: response.data.results,
          next: response.data.next,
          paginationCount: Math.ceil(response.data.count / 10),
          totalShow: response.data.count,
          isLoading: false,
        })
      )
      .catch((error) => {
        if (error.response.status === 400) {
          let err = "";
          for (let [, value] of Object.entries(error.response.data)) {
            err = `${err} ${value[0]}. `;
          }
          toast.error(err);
          this.setState({ isLoading: false });
        }
        console.log(error);
      });
  }

  componentDidMount() {
    let RssPermissionData = this.props.RssPermission.filter(
      (data) => data.name === "rssupdates"
    );
    RssPermissionData[0].subPermissions.map((data) => {
      if (data.method === "view" && data.isPermitted) {
        this.setState({ ViewAuthentication: true });
        this.fetchRss();
      } else if (data.method === "change" && data.isPermitted) {
        this.setState({ ChangeAuthentication: false });
        this.fetchRss()
      } else if (data.method === "delete" && data.isPermitted) {
        this.setState({ deletAuthentication: false });
      }
    });
  }

  editRss = (rss) => {
    this.toggleBannerEditModal();
    console.log("bannerData: ", rss);
    this.setState({
      id: rss.id,
      show_slug: rss.showSlug || "",
      rss_url: rss.rssUrl || "",
      rss_etag: rss.rssEtag || "",
    });
    console.log("rss list state", this.state);
  };
  toggleBannerEditModal = () => {
    this.setState({ editRss: !this.state.editRss });
    this.fetchRss();
  };

  onRssDeleteHandler = async (id) => {
    await deleteRss(id)
      .then((res) => {
        console.log(res);
        toast.success(`Rss deleted successfully!`);
        this.fetchRss();
      })
      .catch((err) => {
        console.log(`Rss delete error`, err);
        toast.error(`Rss delete failed!`);
      });
  };
  GOLiveRss = () => {
    LiveRss()
      .then((res) => {
        console.log(res);
        toast.success(`Rss Feed is  Live successfully!`);
      })
      .catch((err) => {
        console.log(`Rss Live  failed`, err);
        toast.error(`Rss Live failed!`);
      });
  };
  handlePageClick = (data) => {
    // this.showLoader()
    console.log("page", data);
    let selected = data.selected + 1;
    console.log(selected);
    this.props.history.replace("?page=" + selected);
    this.setState({ currentPage: selected, isLoading: true }, () =>
      this.fetchRss(this.state.currentPage)
    );
  };
  render() {
    const loader = (
      <ContentLoader backgroundColor="#c2c2c2">
        <rect x="0" y="56" rx="3" ry="3" width="150" height="4" />
        <rect x="0" y="72" rx="3" ry="3" width="100" height="4" />
      </ContentLoader>
    );
    const { isLoading, rss, error } = this.state;
    // console.log(rss)
    return (
      <React.Fragment>
        {this.state.ViewAuthentication ? (
          <>
            <ToastContainer position="top-right" />
            <Modal
              size="xl"
              show={this.state.editRss}
              onHide={this.toggleBannerEditModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Rss</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <RssAdd
                  data={this.state}
                  editRss={this.state.editRss}
                  close={this.toggleBannerEditModal}
                  fetchRss={this.componentDidMount}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={this.toggleBannerEditModal}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <h1>Rss Links</h1>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Sr. no</th>
                  <th>Show Name</th>
                  <th>Rss link</th>
                  <th>
                    <Button variant="primary" onClick={() => this.GOLiveRss()}>
                      Go Live
                    </Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {error ? <p> {error.message}</p> : null}
                {!isLoading ? (
                  rss.map((rss, index) => {
                    const { showName, rssUrl, id, rssEtag } = rss;
                    return (
                      <tr key={id}>
                        <td>{index + 1}</td>
                        <td>{showName}</td>
                        <td>
                          <a
                            href={rssUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {rssUrl}
                          </a>
                        </td>
                        <td>
                          <Button
                            variant="primary"
                            onClick={() => this.editRss(rss)}
                            disabled={this.state.changeAuthentication}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => {
                              if (
                                window.confirm(
                                  `Are you sure you wish to delete this banner?`
                                )
                              )
                                this.onRssDeleteHandler(id);
                            }}
                            disabled={this.state.deletAuthentication}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td>{loader}</td>
                    <td>{loader}</td>
                    <td>{loader}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.paginationCount}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={this.state.currentPage - 1}
              // initialPage={1}
            ></ReactPaginate>
          </>
        ) : (
          <NotAuthenticatedMsg />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  RssPermission: state.User.permission,
});

export default connect(mapStateToProps)(RssCollection);
