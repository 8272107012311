import http from "../http-common";
import auth from "./authentication";

const baseURL = "https://staging.samortech.com/api";

const getAllChannel = () => {
  return http.get(`${baseURL}/channels/?all=True`);
};

const getAllChannelPagination = (page = 1) => {
  return http.get(`${baseURL}/channels/?page=${page}`);
};

const getChannelDropdown = () => {
  return http.get(`${baseURL}/channels/?all=True&only_select=True`);
};

const getChannel = (id) => {
  return http.get(`${baseURL}/channels/${id}/`);
};

const create = (data) => {
  return http.post(`${baseURL}/channels/`, { data: data });
};

const updateChannel = (id, data) => {
  return http.patch(`${baseURL}/channels/${id}/`, data);
};

const removeChannel = (id) => {
  return http.delete(`${baseURL}/channels/${id}/`);
};

const removeAll = () => {
  return http.delete(`${baseURL}/channels`);
};

const findByTitle = (title) => {
  return http.get(`${baseURL}/channels?title=${title}/`);
};
const channelSequnce = (data) => {
  let sequencedata = {
    channels: data,
  };
  console.log("sequencedata", sequencedata);
  return http.post(`${baseURL}/channels/order_channels/`, sequencedata);
};

export {
  getAllChannel,
  getChannelDropdown,
  getAllChannelPagination,
  getChannel,
  create,
  updateChannel,
  removeChannel,
  removeAll,
  findByTitle,
  channelSequnce,
  baseURL,
};
