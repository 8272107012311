import React, { Component } from "react";
import { Table, Button, Modal, Form, Col, Image } from "react-bootstrap";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import auth from "../../../services/authentication";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "react-toastify/dist/ReactToastify.css";
import {
  getArtistDropDown,
  getAllArtist,
  removeArtist,
  updateArtist,
} from "../../../services/serviceArtists";
import AddArtist from "./addArtist";
import { connect } from "react-redux";
import NotAuthenticatedMsg from "../../NotAuthenticatedMsg";

class AllArtist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      next: "not null",
      totalBanner: 0,
      paginationCount: 0,
      currentPage: 1,
      Artist: [],
      isLoading: false,
      isEdit: false,
      imageUpdating: false,
      editedArtist: undefined,
      ViewAuthentication: false,
      ChangeAuthentication: true,
      deletAuthentication: true,
    };
  }

  hideLoader = () => {
    this.setState({ isLoading: false });
  };

  showLoader = () => {
    this.setState({ isLoading: true });
  };

  async componentDidMount() {
    //Load all the Artist
    let ArtistPermissionData = this.props.ArtistPermission.filter(
      (data) => data.name === "artist"
    );
    ArtistPermissionData[0].subPermissions.map((data) => {
      if (data.method === "view" && data.isPermitted) {
        this.setState({ ViewAuthentication: true });
      } else if (data.method === "change" && data.isPermitted) {
        this.setState({ ChangeAuthentication: false });
      } else if (data.method === "delete" && data.isPermitted) {
        console.log("inside deletee");
        this.setState({ deletAuthentication: false });
      }
    });
    this.showLoader();
    await this.fetchArtistsData(this.state.currentPage);
  }

  async onDelete(uniqueSlug, name) {
    await removeArtist(uniqueSlug)
      .then((response) => {
        if (response.status === 204) {
          toast.success(`Artist ${name} deleted successfully.`);
          this.componentDidMount();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Artist ${name} delete failed, try again later.`);
      });
  }

  handleClose = () => {
    this.setState({ isEdit: false });
  };
  handleShow = () => {
    this.setState({ isEdit: true });
  };

  toggleEdit = (show) => {
    this.setState(
      {
        editedArtist: show,
        isEdit: !this.isEdit,
      },
      () => console.log("Edited Artist: ", this.state.editedArtist)
    );
  };

  changeHandler = (event) => {
    let show = this.state.editedArtist;
    let editShow = {
      ...show,
      [event.target.name]: event.target.value,
    };
    this.setState({ editedArtist: editShow });
  };

  updateHandler = (e) => {
    e.preventDefault();
    this.handleClose();
    let data = {
      name: this.state.editedArtist.name,
      color: this.state.editedArtist.color,
    };
    updateArtist(this.state.editedArtist.uniqueSlug, data)
      .then((response) => {
        if (response.status === 200) {
          alert("Artist updated successfully");
          return this.componentDidMount();
        }
      })
      .catch((error) => {
        if (data.name == "") {
          toast.error("name field is mandatory");
        } else if (data.color == "") {
          toast.error("color field is mandatory");
        } else if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = ` ${value}. `;
          }
          toast.error(err);
          this.setState({ isLoading: false });
        }
      });
  };
  fetchArtistsData = (page) => {
    getAllArtist(page)
      .then((response) => {
        console.log(response.data.results);
        this.setState({
          Artist: response.data.results,
          next: response.data.next,
          paginationCount: Math.ceil(response.data.count / 10),
          totalShow: response.data.count,
        });
        this.hideLoader();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePageClick = (data) => {
    // this.showLoader()
    console.log("page", data);
    let selected = data.selected + 1;
    this.props.history.replace("?page=" + selected);
    this.setState({ currentPage: selected }, () =>
      this.fetchArtistsData(this.state.currentPage)
    );
  };
  render() {
    const { Artist } = this.state;
    let imgHash = Date.now();

    return (
      <div>
        {this.state.ViewAuthentication ? (
          <>
            <ToastContainer position="top-center" />
            {this.state.isEdit ? (
              <Modal
                size="xl"
                show={this.state.isEdit}
                onHide={this.handleClose}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit Artist</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <AddArtist
                    editedArtist={this.state.editedArtist}
                    fetchArtistsData={this.fetchArtistsData}
                    handleClose={this.handleClose}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            ) : null}
            <h2>List of Artist</h2>
            {this.state.isLoading ? (
              <Loader
                type="ThreeDots"
                color="#eb1163"
                height={100}
                width={50}
              />
            ) : (
              <Table responsive hover>
                <thead>
                  <tr>
                    <th scope="col">Sr.no</th>
                    <th scope="col">Image</th>
                    <th scope="col">Name</th>
                    <th scope="col">Actions</th>
                    <th scope="col">TagLine</th>
                  </tr>
                </thead>
                <tbody>
                  {Artist.length
                    ? Artist.map((Artist, index) => (
                        <tr key={index}>
                          <td key={index}>{index + 1}</td>
                          <td>
                            {Artist.picUrl ? (
                              <Image
                                src={`${Artist.picUrl}?${imgHash}`}
                                thumbnail
                                width="80px"
                              />
                            ) : (
                              <p>No image</p>
                            )}
                          </td>
                          <td>{Artist.name}</td>
                          <td>{Artist.tagLine}</td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={() => this.toggleEdit(Artist)}
                              disabled={this.state.ChangeAuthentication}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="danger"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    `Are you sure you wish to delete Artist ${Artist.name}?`
                                  )
                                )
                                  this.onDelete(Artist.uniqueSlug, Artist.name);
                              }}
                              disabled={this.state.deletAuthentication}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            )}

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.paginationCount}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={this.state.currentPage - 1}
              // initialPage={1}
            ></ReactPaginate>
          </>
        ) : (
          <NotAuthenticatedMsg />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  ArtistPermission: state.User.permission,
});

export default connect(mapStateToProps)(AllArtist);
