import React, { Component } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Form, Col, Button, Figure } from "react-bootstrap";
import { getChannelDropdown, baseURL } from "../../../services/serviceChannels";
import { getArtistDropDown } from "../../../services/serviceArtists";
import { getBadgeDropDown } from "../../../services/serviceBadges";
import {
  getCategoryDropDowns,
  getAllCategory,
} from "../../../services/serviceCategories";
import { getShow, updateShow } from "../../../services/serviceShows";
import { AllAdList } from "../../../services/serviceAds";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import auth from "../../../services/authentication";
import Select from "react-select";
import update from "immutability-helper";
import { ConeStriped } from "react-bootstrap-icons";
import { getAllLanguages } from "../../../services/serviceLanguages";
import { connect } from "react-redux";
import NotAuthenticatedMsg from "../../NotAuthenticatedMsg";

class NewShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      weight: "",
      short_description: "",
      description: "",
      sequence: "",
      language: "",
      short_url: "",
      dateTime: "",
      published_on: `${moment(new Date()).format()}`,
      bannerShowImageView: undefined,
      banner_image: "",
      image_url: "",
      banner_image_url: "",
      featured_image_url: "",
      featuredShowImageView: undefined,
      featured_image: "",
      season: "",
      artist_slug: "",
      artists: [],
      badges: [],
      badge_slug: "",
      channels: [],
      categories: [],
      category_slug: "",
      channel_slug: "",
      Ad: [],
      Languages: [],
      ad_slugs: "",
      isLoading: false,
      disabled: false,
      is_active: true,
      uniqueSlug: "",
      is_active: true,
      episode_ordering: "",
      alt_title: "",
      Authentication: false,
    };
  }

  componentDidMount = () => {
    let ShowPermissionData = this.props.ShowPermission.filter(
      (data) => data.name === "show"
    );
    ShowPermissionData[0].subPermissions.map((data) => {
      if (data.method === "add" && data.isPermitted) {
        this.setState({ Authentication: true });

        getAllLanguages()
          .then((res) => {
            console.log("language", res.data.results);
            let newOptions = [];
            let Language = res.data.results;

            Language.forEach((data) => {
              newOptions.push({ value: data.uniqueSlug, label: data.name });
            });
            // this.setState({ categories: newOptions })
            this.setState({ Languages: newOptions });
          })
          .catch((err) => {
            console.log(err);
          });
        if (this.props.data) {
          console.log(this.props.data);
          getShow(this.props.data, this.props.filter)
            .then((res) => {
              if (res.status === 200) {
                console.log("Response form API: ", res.data);
                let newOptions = [];
                res.data.categories.forEach((data) => {
                  newOptions.push({ value: data.uniqueSlug, label: data.name });
                });
                // this.setState({ category_slug: newOptions })
                let newopcha = [];
                res.data.channels.forEach((data) => {
                  newopcha.push({ value: data.uniqueSlug, label: data.title });
                });
                // this.setState({ channel_slug: newopcha })
                // let newads = []
                // res.data.ads.forEach(data => {
                //   newads.push({ Ad: data.uniqueSlug, label: data.title })
                // })
                // this.setState({ ad_slugs: newads })
                this.setState({
                  title: res.data.title,
                  weight: res.data.weight,
                  published_on: res.data.publishedOn,
                  date: res.data.publishedOn,
                  bannerShowImageView: res.data.bannerImageUrl,
                  banner_image_url: res.data.bannerImageUrl,
                  featured_image_url: res.data.featuredImageUrl,
                  featuredShowImageView: res.data.featuredImageUrl,
                  short_description: res.data.shortDescription,
                  description: res.data.description,
                  language: res.data.language,
                  sequence: res.data.sequence,
                  short_url: res.data.shortUrl,
                  season: res.data.season,
                  uniqueSlug: res.data.uniqueSlug,
                  category_slug: newOptions,
                  channel_slug: newopcha,
                  is_active: res.data.isActive,
                  episode_ordering: res.data.episodeOrdering,
                  alt_title: res.data.altTitle,
                  // ad_slugs: newads,
                });
                if (res.data.artist != null) {
                  this.setState({ artist_slug: res.data.artist.uniqueSlug });
                }
                if (res.data.badge != null) {
                  this.setState({ badge_slug: res.data.badge.uniqueSlug });
                }
                console.log("show publish date", res.data.uniqueSlug);
              }
            })
            .catch((error) => {
              //   if (error.response.status === 400) {
              //     let err = "";
              //     for (let [key, value] of Object.entries(error.response.data)) {
              //         err = `${key}: ${value[0]}. `
              //     }
              //     toast.error(err)
              //     this.setState({ isLoading: false })
              // }else{
              //   console.log(error)
              // }
              console.log(error);
            });
        }
        getChannelDropdown()
          .then((response) => {
            let newOptions = [];
            let channels = response.data;

            channels.forEach((data) => {
              newOptions.push({ value: data.uniqueSlug, label: data.title });
            });
            this.setState({ channels: newOptions });
          })
          .catch((error) => {
            toast.error("Channel fetch failed");
            console.log(error);
          });

        getCategoryDropDowns()
          .then((response) => {
            let newOptions = [];
            let categories = response.data;

            categories.forEach((data) => {
              newOptions.push({ value: data.uniqueSlug, label: data.name });
            });
            this.setState({ categories: newOptions });
          })
          .catch((error) => {
            toast.error("Category fetch failed");
            console.log(error);
          });
        getBadgeDropDown()
          .then((response) => {
            this.setState({ badges: response.data });
          })
          .catch((error) => {
            toast.error("Badge fetch failed");
            console.log(error);
          });

        getArtistDropDown()
          .then((response) => {
            this.setState(() => {
              return { artists: response.data, isLoading: false };
            });
          })
          .catch((error) => {
            toast.error("Artist fetch failed");
            console.log(error);
          });
        // AllAdList()
        //   .then(response => {
        //     let newOptions = []
        //     let Ad = response.data

        //     Ad.forEach(data => {
        //       newOptions.push({ value: data.uniqueSlug, label: data.title })
        //     })
        //     this.setState({ Ad: newOptions })
        //   })
        //   .catch(error => {
        //     toast.error("Ads fetch failed")
        //     console.log(error)
        //   });
      }
    });
  };

  handleDateChange = (date) => {
    this.setState({
      published_on: moment(date).format(),
      dateTime: date,
    });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name == "image_url" && e.target.value != "") {
      this.setState({
        disabled: true,
        bannerShowImageView: undefined,
        banner_image: "",
        banner_image_url: "",
        featured_image_url: "",
        featuredShowImageView: undefined,
        featured_image: "",
      });
    } else if (e.target.name == "image_url" && e.target.value == "") {
      this.setState({ disabled: false });
    }
  };

  onFeaturedShowImageChange = (event) => {
    this.setState({
      featuredShowImageView: undefined,
      featured_image: undefined,
    });
    if (event.target.files[0]) {
      let reader = new FileReader();
      this.setState({ featured_image: event.target.files[0] });
      reader.onload = (e) => {
        this.setState({ featuredShowImageView: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  onBannerShowImageChange = (event) => {
    this.setState({ bannerShowImageView: undefined, banner_image: undefined });
    if (event.target.files[0]) {
      let reader = new FileReader();
      this.setState({ banner_image: event.target.files[0] });
      reader.onload = (e) => {
        this.setState({ bannerShowImageView: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);
    this.setState({ isLoading: true });
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "weight":
          formData.append(`${key}`, parseInt(value));
          break;
        case "sequence":
          formData.append(`${key}`, parseInt(value));
          break;
        // case 'category_slug':
        //     formData.append(`${key}`, this.contentSlugApiHandler(key))
        //     break;
        // case 'channel_slug':
        //     formData.append(`${key}`, this.contentSlugApiHandler(key))
        //     break;
        case "bannerShowImageView":
        case "featuredShowImageView":
        case "artists":
        case "deepLink":
        case "badges":
        case "categories":
        case "channels":
        case "dateTime":
        case "isLoading":
          break;
        case "category_slug":
          if (this.state.category_slug != "") {
            let Section = this.state.category_slug;
            //   event.forEach(data => {
            //     Section.push({ value: data.value })
            // })
            // let val1 = Section.map(data => {
            //   return data.value
            // })

            let val = JSON.stringify(Section);
            // this.setState({category_slug:""})
            // let val2 = JSON.stringify(key, val)
            // console.log("cat", val2)
            formData.append(`${key}`, val);
          }
          break;
        case "channel_slug":
          if (this.state.channel_slug != "") {
            let Section = this.state.channel_slug;
            //   event.forEach(data => {
            //     Section.push({ value: data.value })
            // })
            // let val1 = Section.map(data => {
            //   return data.value
            // })

            let val = JSON.stringify(Section);
            // this.setState({channel_slug:""})
            // let val2 = JSON.stringify( val)
            // console.log("cha", val)
            formData.append(`${key}`, val);
          }
          break;
        case "ad_slugs":
          if (this.state.ad_slugs != "") {
            let Section = this.state.ad_slugs;
            //   event.forEach(data => {
            //     Section.push({ value: data.value })
            // })
            let val1 = Section.map((data) => {
              return data.value;
            });

            let val = val1.toString();
            // this.setState({category_slug:""})
            formData.append(`${key}`, val);
          }
          break;
        case "other_languages":
          if (this.state.other_languages != "") {
            let Section = this.state.other_languages;
            //   event.forEach(data => {
            //     Section.push({ value: data.value })
            // })
            let val1 = Section.map((data) => {
              return data.value;
            });

            let val = val1.toString();
            // this.setState({category_slug:""})
            formData.append(`${key}`, val);
          }
          break;
        case "short_url":
          if (this.state.short_url != "") {
            formData.append(`${key}`, value);
          }
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    //   for (var [key, value] of formData.entries()) {
    //     // if (value === '' || value === undefined || value === {}) {
    //     //     this.setState({ isLoading: false })
    //     //     return toast.error(`${key} is required!`)
    //     // }
    //      console.log(key + ': ' + value);
    // }

    axios({
      url: `${baseURL}/shows/`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      auth: auth,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(`New Show ${response.data.title} added successfully.`);
          this.setState({
            isLoading: false,
            title: "",
            weight: "",
            short_description: "",
            description: "",
            sequence: "",
            language: "",
            short_url: "",
            bannerShowImageView: undefined,
            banner_image: "",
            image_url: "",
            banner_image_url: "",
            featured_image_url: "",
            featuredShowImageView: undefined,
            featured_image: "",
            season: "",
            artist_slug: "",
            category_slug: "",
            channel_slug: "",
            badge_slug: "",
            disabled: false,
            ad_slugs: "",
            is_active: true,
            episode_ordering: "",
            alt_title: "",
            // artists: [],
            // badges: [],
            // channels: [],
            // categories: []
          });
        }
        if (response.status === 400) {
          toast.error("Something went wrong, please try again");
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 400) {
          toast.error(JSON.stringify(error.response.data));
          this.setState({ isLoading: false });
        }
        console.log(error);
      });
  };
  updateHandler = (e) => {
    e.preventDefault();
    // this.handleClose()
    console.log("data: ", this.state);
    this.setState({ isLoading: true });
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "weight":
        case "sequence":
          formData.append(`${key}`, parseInt(value));
          break;

        case "bannerImageView":
        case "featuredImageView":
        case "isLoading":
        case "artists":
        case "badges":
        case "categories":
        case "channels":
        case "uniqueSlug":
        case "dateTime":
          break;
        case "category_slug":
          if (this.state.category_slug != "") {
            let Section = this.state.category_slug;
            //   event.forEach(data => {
            //     Section.push({ value: data.value })
            // })
            // let val1 = Section.map(data => {
            //   return data.value
            // })

            let val = JSON.stringify(Section);
            // this.setState({category_slug:""})
            // let val2 = JSON.stringify(key, val)
            // console.log("cat", val2)
            formData.append(`${key}`, val);
          }
          break;
        case "channel_slug":
          if (this.state.channel_slug != "") {
            let Section = this.state.channel_slug;
            //   event.forEach(data => {
            //     Section.push({ value: data.value })
            // })
            // let val1 = Section.map(data => {
            //   return data.value
            // })

            let val = JSON.stringify(Section);
            // this.setState({channel_slug:""})
            // let val2 = JSON.stringify( val)
            // console.log("cha", val)
            formData.append(`${key}`, val);
          }
          break;
        case "ad_slugs":
          if (this.state.ad_slugs != "") {
            let Section = this.state.ad_slugs;
            //   event.forEach(data => {
            //     Section.push({ value: data.value })
            // })
            let val1 = Section.map((data) => {
              return data.value;
            });

            let val = val1.toString();
            // this.setState({category_slug:""})
            formData.append(`${key}`, val);
          }
          break;
        case "short_url":
          if (this.state.short_url != "") {
            formData.append(`${key}`, value);
          } else {
            return toast.error(`${key} is required!`);
          }
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    axios({
      url: `${baseURL}/shows/${this.state.uniqueSlug}/`,
      method: "PATCH",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      auth: auth,
    })
      .then((response) => {
        if (response.status === 200) {
          this.props.toggleBannerEditModal();
          this.props.fetchData();
          toast.success(`Show ${response.data.title} updated successfully!`);

          //   window.location.reload(false);
        }
        // if(response.status === 400){
        //     toast.error('Something went wrong, please try again')
        //   this.setState({isLoading: false})
        // }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key}: ${value[0]}. `;
          }
          toast.error(err);
          this.setState({ isLoading: false });
        }
        console.log(error);
      });
  };

  selectHandler = (event, value) => {
    value.name === "channel_slug"
      ? this.setState({ channel_slug: event })
      : value.name === "category_slug"
      ? this.setState({ category_slug: event })
      : value.name === "other_languages"
      ? this.setState({ other_languages: event })
      : this.setState({ ad_slugs: event });
  };
  handelCheckbox = () => {
    this.setState({ is_active: !this.state.is_active });
  };

  render() {
    // console.log("state date", this.state);
    const badges = this.state.badges;
    const channels = this.state.channels;
    const categories = this.state.categories;
    const {
      episode_ordering,
      is_active,
      image_url,
      featured_image_url,
      banner_image_url,
      title,
      weight,
      sequence,
      short_description,
      description,
      color,
      short_url,
      artists,
      ad_slugs,
      Ad,
      alt_title,
    } = this.state;
    return (
      <div>
        {this.state.Authentication || this.props.update ? (
          <>
            <ToastContainer />
            <h2>Add New Show</h2>
            <Form onSubmit={this.submitHandler}>
              <Form.Group>
                <Form.Row>
                  <Col>
                    <Form.Label>
                      Show Name: <span className="asterisk"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Show Name here"
                      name="title"
                      value={title}
                      onChange={this.changeHandler}
                    />
                  </Col>
                </Form.Row>
                <br />
                <Form.Group>
                  <Form.Label>
                    Alternative Tilte:<span className="asterisk"> *</span>
                  </Form.Label>
                  <Form.Text className="text-muted">
                    "If the title is in a language other than English, change it
                    to eg: "लल्लन चला अमेरिका" by writing "lallan chala America"
                  </Form.Text>
                  <Form.Control
                    type="text"
                    placeholder="alternative title"
                    name="alt_title"
                    value={alt_title}
                    onChange={this.changeHandler}
                    required
                  />
                  <Form.Label>
                    Description: <span className="asterisk"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="4"
                    placeholder="Description"
                    name="description"
                    value={description}
                    onChange={this.changeHandler}
                  />
                  <Form.Label>Short Description:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="2"
                    placeholder="Short Description"
                    name="short_description"
                    value={short_description}
                    onChange={this.changeHandler}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Row>
                    <Col>
                      <Form.Label>Featured Image: </Form.Label>
                      {this.state.featuredShowImageView ? (
                        <Figure>
                          <Figure.Image
                            src={this.state.featuredShowImageView}
                            thumbnail
                            width={171}
                            height={180}
                          />
                        </Figure>
                      ) : (
                        <p>No image selected</p>
                      )}
                      <Form.File
                        type="file"
                        name="featured_image"
                        label="featured Image Upload"
                        accept="image/*"
                        disabled={this.state.disabled}
                        onChange={this.onFeaturedShowImageChange}
                        custom
                      />
                    </Col>
                    <Col>
                      <Form.Label>Featured Image URL: </Form.Label>
                      <br />
                      <br />
                      {/* <p>:</p> */}
                      <Form.Control
                        type="text"
                        placeholder="Featured Image URL"
                        name="featured_image_url"
                        value={featured_image_url}
                        disabled={this.state.disabled}
                        onChange={this.changeHandler}
                      />
                      <br />
                    </Col>
                  </Form.Row>
                </Form.Group>

                <Form.Label>Image URL: </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Image URL"
                  name="image_url"
                  value={image_url}
                  onChange={this.changeHandler}
                />
                <br />
                <Form.Group>
                  <Form.Group>
                    <Form.Row>
                      <Col>
                        <Form.Label>Banner Image: </Form.Label>
                        {this.state.bannerShowImageView ? (
                          <Figure>
                            <Figure.Image
                              src={this.state.bannerShowImageView}
                              thumbnail
                              width={171}
                              height={180}
                            />
                          </Figure>
                        ) : (
                          <p>No image selected</p>
                        )}
                        <Form.File
                          type="file"
                          name="banner_image"
                          label="banner Image Upload"
                          accept="image/*"
                          onChange={this.onBannerShowImageChange}
                          disabled={this.state.disabled}
                          custom
                        />
                      </Col>
                      <Col>
                        <Form.Label>Banner Image URL: </Form.Label>
                        <br />
                        <br />
                        <Form.Control
                          type="text"
                          placeholder="Banner Image URL"
                          name="banner_image_url"
                          value={banner_image_url}
                          onChange={this.changeHandler}
                          disabled={this.state.disabled}
                        />
                        <br />
                      </Col>
                    </Form.Row>
                  </Form.Group>

                  <Form.Row>
                    <Col>
                      <Form.Label>Published On: </Form.Label>
                      <DatePicker
                        name="published_on"
                        selected={this.state.dateTime}
                        value={this.state.published_on}
                        onChange={this.handleDateChange}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                      />
                      {}
                    </Col>
                    <Col>
                      <div style={{ display: "flex" }}>
                        <Form.Label>
                          Is Active: <span className="asterisk"> *</span>
                        </Form.Label>{" "}
                        <br></br>
                        <Form.Control
                          style={{
                            width: "14px",
                            height: "27px",
                            marginLeft: "12px",
                          }}
                          type="checkbox"
                          name="is_active"
                          checked={this.state.is_active}
                          onClick={this.handelCheckbox}
                        />
                      </div>
                    </Col>
                  </Form.Row>
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Row>
                    <Col>
                      <Form.Label>
                        Weight: <span className="asterisk"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Weight"
                        min="1"
                        name="weight"
                        value={weight}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Col>
                      <Form.Label>
                        sequence: <span className="asterisk"> *</span>{" "}
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="sequence"
                        min="1"
                        name="sequence"
                        value={sequence}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Artist: </Form.Label>
                      <Form.Control
                        as="select"
                        name="artist_slug"
                        value={this.state.artist_slug}
                        onChange={this.changeHandler}
                      >
                        <option defaultValue value="">
                          Select Artist
                        </option>
                        {artists.map((data, index) => (
                          <option key={index} value={data.uniqueSlug}>
                            {data.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>
                        Language: <span className="asterisk"> *</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="language"
                        value={this.state.language}
                        onChange={this.changeHandler}
                      >
                        <option defaultValue value="">
                          Select a Language
                        </option>
                        {this.state.Languages.map((data, index) => (
                          <option key={index} value={data.label}>
                            {data.label}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Row>
                </Form.Group>
                <Form.Group>
                  <Form.Row>
                    <Col>
                      <Form.Label>Season: </Form.Label>
                      <br></br>
                      <Form.Control
                        as="select"
                        name="season"
                        value={this.state.season}
                        onChange={this.changeHandler}
                      >
                        <option defaultValue value="">
                          Select a season
                        </option>
                        <option value="Season 1">Season 1</option>
                        <option value="Season 2">Season 2</option>
                        <option value="Season 3">Season 3</option>
                        <option value="Season 4">Season 4</option>
                        <option value="Season 5">Season 5</option>
                        <option value="Season 6">Season 6</option>
                        <option value="Season 7">Season 7</option>
                        <option value="Season 8">Season 8</option>
                        <option value="Season 9">Season 9</option>
                      </Form.Control>
                    </Col>
                    <Col>
                      <Form.Label>Badge: </Form.Label>
                      <Form.Control
                        as="select"
                        name="badge_slug"
                        value={this.state.badge_slug}
                        onChange={this.changeHandler}
                      >
                        <option defaultValue value="">
                          Select Badge
                        </option>
                        {badges.map((data, index) => (
                          <option key={index} value={data.uniqueSlug}>
                            {data.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>

                    <Col>
                      <Form.Label>Short URL:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Short URL"
                        name="short_url"
                        value={short_url}
                        disabled={this.props.update ? false : true}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </Form.Row>
                </Form.Group>
                <Form.Group>
                  <Form.Row>
                    <Col>
                      <Form.Label>Advertisement: </Form.Label>
                      <Select
                        isMulti
                        defaultValue
                        value={this.state.ad_slugs}
                        styles={{
                          control: (styles) => ({ ...styles, width: 300 }),
                        }}
                        name="ad_slugs"
                        onChange={this.selectHandler}
                        options={this.state.Ad}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Channel: </Form.Label> <br></br>
                      <Select
                        isMulti
                        defaultValue=""
                        value={this.state.channel_slug}
                        styles={{
                          control: (styles) => ({ ...styles, width: 300 }),
                        }}
                        name="channel_slug"
                        onChange={this.selectHandler}
                        options={this.state.channels}
                      />
                    </Col>
                    <Col>
                      <Form.Label>
                        Category: <span className="asterisk"> *</span>
                      </Form.Label>{" "}
                      <br></br>
                      <Select
                        isMulti
                        defaultValue
                        value={this.state.category_slug}
                        styles={{
                          control: (styles) => ({ ...styles, width: 300 }),
                        }}
                        name="category_slug"
                        onChange={this.selectHandler}
                        options={this.state.categories}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <Form.Label>
                        Other Languages: <span className="asterisk"> *</span>
                      </Form.Label>{" "}
                      <br></br>
                      <Select
                        isMulti
                        defaultValue
                        value={this.state.other_languages}
                        styles={{
                          control: (styles) => ({ ...styles, width: 300 }),
                        }}
                        name="other_languages"
                        onChange={this.selectHandler}
                        options={this.state.Languages}
                      />
                    </Col>
                    <Col>
                      <Form.Label>
                        Episode Ordering: <span className="asterisk"> </span>
                      </Form.Label>
                      <br></br>
                      <Form.Control
                        as="select"
                        name="episode_ordering"
                        value={this.state.episode_ordering}
                        onChange={this.changeHandler}
                      >
                        <option defaultValue value="">
                          Select Episode Ordering
                        </option>
                        <option value="sequence">Ascending</option>
                        <option value="-sequence">Descending</option>
                      </Form.Control>
                    </Col>
                  </Form.Row>
                </Form.Group>
              </Form.Group>

              {/* <Form.Group>
              <Form.Label>Color: <span className="asterisk"> *</span></Form.Label>
                <Form.Control type="color" placeholder="color" name="color" value={color} onChange={this.changeHandler}/>
              </Form.Group> */}

              <Form.Group>
                {/* <Form.Text className="text-muted">eg: https://www.aawaz.com/showName</Form.Text> */}
              </Form.Group>

              {this.props.update ? (
                <Button onClick={this.updateHandler}>Update</Button>
              ) : (
                <Button type="submit" variant="info">
                  Publish
                </Button>
              )}
            </Form>
          </>
        ) : (
          <NotAuthenticatedMsg />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  ShowPermission: state.User.permission,
});

export default connect(mapStateToProps)(NewShow);
