import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { withRouter, Redirect, useHistory } from "react-router-dom";
import "./Login.css";
import logo from "../../images/aawaz-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { SetLogin } from "../../Redux/Feature/user";
import Axios from "axios";
import httpCommon from "../../http-common";
import httpMultpart from "../../http-multpart";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    Axios.post("https://staging.samortech.com/api/cms-login/", {
      username,
      password,
    })
      .then((res) => {
        console.log("res.data", res.data);
        httpCommon.defaults.headers.common[
          "Authorization"
        ] = `Token ${res.data.token}`;
        httpMultpart.defaults.headers.common[
          "Authorization"
        ] = `Token ${res.data.token}`;

        dispatch(
          SetLogin({
            token: res.data.token,
            Permissions: res.data.permissionData,
          })
        );

        localStorage.setItem("LoggedIn", "true");
        localStorage.setItem("token", res.data.token);
        localStorage.setItem(
          "permissionData",
          JSON.stringify(res.data.permissionData)
        );
        history.push(`/`);
      })
      .catch((err) => {
        console.log("logoin err", err);
      });
  };
  return (
    <div>
      <div className="login-screen">
        <div className="login-form-header">
          <img src={logo} className="logo" alt="Aawaz logo" />
          <h2 className="login-title">Login Page</h2>
        </div>
        <Form className="form-body" error={error} onSubmit={(e) => onSubmit(e)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              className="login-input"
              type="text"
              placeholder="Enter username"
              name="username"
              onChange={(e) => setUsername(e.target.value)}
            />
            <Form.Text className="text-muted">
              Never share your username and password with anyone.
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Control
              className="login-input"
              type="password"
              placeholder="Password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button
            className="login-button"
            variant="outline-light"
            type="submit"
          >
            Login
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default withRouter(Login);
