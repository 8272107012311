import http from '../http-common';
import auth from './authentication';
const baseURL = "https://staging.samortech.com/api";

const getAllLanguages = () => {
    return http.get(`${baseURL}/app-language/`, );
};
const removeLanguages = (id) => {
    return http.delete(`${baseURL}/app-language/${id}/`, );
};

const createLanguages = data => {
    return http.post(`${baseURL}/app-language/`, { data: data }, );
}
const getBySlugLanguages = slug => {
    return http.get(`${baseURL}/app-language/${slug}`, );
}
const EditLanguages = (id, data) => {
    return http.patch(`${baseURL}/app-language/${id}/`, { data: data }, );
}


export {
    getAllLanguages, removeLanguages, createLanguages, getBySlugLanguages, EditLanguages
}