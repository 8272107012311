import http from "../http-common";
import http2 from "../http-multpart";
import auth from "./authentication";
import { Token } from "./Token";
import axios from "axios";

const baseURL = "https://staging.samortech.com/api";
const BearerToken = Token();

const getAllBanner = () => {
  return http.get(`${baseURL}/banners/?all=True`);
};
const getAllBannerV2 = (page = 1, filter) => {
  if (filter !== "" && filter !== undefined) {
    return http.get(
      `${baseURL}/banners-v2/?cms=True&is_active=${filter}&page=${page}`
    );
  } else {
    return http.get(`${baseURL}/banners-v2/?cms=True&page=${page}`);
  }
};

const getBannerDropDown = () => {
  return http.get(`${baseURL}/banners/?all=True&only_select=True`);
};

const getBanner = (id) => {
  return http.get(`${baseURL}/banners/${id}/`);
};

const createBanner = (data) => {
  return http2.post(`${baseURL}/banners/`, data);
};

const createBannerv2 = (data) => {
  return http2.post(`${baseURL}/banners-v2/?cms=True`, data);
};

const updateBanner = (id, data) => {
  return http.patch(`${baseURL}/banners/${id}/`, data);
};
const updateBannerV2 = (id, data, filter) => {
  return http.patch(
    `${baseURL}/banners-v2/${id}/?cms=True&is_active=${filter}`,
    data
  );
};

const removeBanner = (id) => {
  return http.delete(`${baseURL}/banners/${id}/`);
};

const removeBannerv2 = (id) => {
  return http.delete(`${baseURL}/banners-v2/${id}/?cms=True`);
};

const removeAllBanner = () => {
  return http.delete(`${baseURL}/banners/`);
};

const findByTitleBanner = (title) => {
  return http.get(`${baseURL}/banners/?title=${title}`);
};
export {
  getAllBanner,
  getBannerDropDown,
  getBanner,
  createBanner,
  updateBanner,
  removeBanner,
  removeAllBanner,
  findByTitleBanner,
  createBannerv2,
  removeBannerv2,
  updateBannerV2,
  getAllBannerV2,
  baseURL,
};
