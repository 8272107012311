import React, { Component } from "react";
import { getAllGroup, removeGroup } from "../../../services/serviceGroup";
import { Table, Button, Modal, Form, Figure } from "react-bootstrap";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateGroup from "./CreateGroup";
import { connect } from "react-redux";
import NotAuthenticatedMsg from "../../NotAuthenticatedMsg";

class AllGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Group: [],
      isLoading: false,
      isEdit: false,
      editedGroup: {},
      ViewAuthentication: false,
      ChangeAuthentication: true,
      deletAuthentication: true,
    };
  }

  hideLoader = () => {
    this.setState({ isLoading: false });
  };

  showLoader = () => {
    this.setState({ isLoading: true });
  };

  async componentDidMount() {
    //Load all the Group
    let GroupPermissionData = this.props.GroupPermission.filter(
      (data) => data.name === "cmsusers"
    );
    GroupPermissionData[0].subPermissions.map((data) => {
      if (data.method === "view" && data.isPermitted) {
        this.setState({ ViewAuthentication: true });
        this.FetchGropuData();
      } else if (data.method === "change" && data.isPermitted) {
        this.setState({ ChangeAuthentication: false });
      } else if (data.method === "delete" && data.isPermitted) {
        this.setState({ deletAuthentication: false });
      }
    });
  }

  FetchGropuData = () => {
    this.showLoader();
    getAllGroup()
      .then((response) => {
        console.log(response.data);
        this.setState({ Group: response.data.results });
        this.hideLoader();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async onDelete(id, name) {
    await removeGroup(id)
      .then((response) => {
        if (response.status === 204) {
          alert(`Group ${name} deleted successfully.`);
          this.componentDidMount();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(`Group ${name} delete failed, try again later.`);
      });
  }

  handleClose = () => {
    this.setState({ isEdit: false });
  };
  handleShow = () => {
    this.setState({ isEdit: true });
  };

  toggleEdit = (data) => {
    console.log(data);
    this.setState({
      editedGroup: data,
      isEdit: !this.state.isEdit,
    });
  };

  render() {
    const { Group } = this.state;

    return (
      <div>
        {this.state.ViewAuthentication ? (
          <>
            {this.state.isEdit ? (
              <Modal
                size="xl"
                show={this.state.isEdit}
                onHide={this.handleClose}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <CreateGroup isEdit={true} data={this.state.editedGroup} />
                </Modal.Body>
              </Modal>
            ) : null}
            <h2>List of Groups</h2>
            {this.state.isLoading ? (
              <Loader
                type="ThreeDots"
                color="#eb1163"
                height={100}
                width={50}
              />
            ) : (
              <Table responsive hover>
                <thead>
                  <tr>
                    <th scope="col">Sr.no</th>
                    <th scope="col">name</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {Group.length
                    ? Group.map((data, index) => (
                        <tr key={index}>
                          <td key={index}>{index + 1}</td>

                          <td>{data.name}</td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={() => this.toggleEdit(data)}
                              disabled={this.state.ChangeAuthentication}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="danger"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    `Are you sure you wish to delete Group ${data.name}?`
                                  )
                                )
                                  this.onDelete(data.id, data.name);
                              }}
                              disabled={this.state.deletAuthentication}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            )}
          </>
        ) : (
          <NotAuthenticatedMsg />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  GroupPermission: state.User.permission,
});

export default connect(mapStateToProps)(AllGroup);
