import React, { Component } from "react";
import { getAllCmsUser, removeCmsUser } from "../../../services/serviceCmsUser";
import {
  Form,
  Col,
  Button,
  Row,
  Table,
  Container,
  Modal,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import CreateUser from "./CreateUser";
import { connect } from "react-redux";
import NotAuthenticatedMsg from "../../NotAuthenticatedMsg";

class AllCmsUser extends Component {
  state = {
    next: "",
    totalSections: 0,
    paginationCount: 0,
    currentPage: 1,
    CmsUserData: [],
    editCmsUser: false,
    editedData: [],
    ViewAuthentication: false,
    ChangeAuthentication: true,
    deletAuthentication: true,
  };

  componentDidMount = () => {
    let UserPermissionData = this.props.UserPermission.filter(
      (data) => data.name === "user"
    );
    UserPermissionData[0].subPermissions.map((data) => {
      if (data.method === "view" && data.isPermitted) {
        this.setState({ ViewAuthentication: true });
        this.fetchCmsUser(this.state.currentPage);
      } else if (data.method === "change" && data.isPermitted) {
        this.setState({ ChangeAuthentication: false });
        this.fetchCmsUser(this.state.currentPage);
      } else if (data.method === "delete" && data.isPermitted) {
        this.setState({ deletAuthentication: false });
      }
    });
  };
  fetchCmsUser = (page) => {
    getAllCmsUser(page)
      .then((response) => {
        this.setState({
          CmsUserData: response.data.results,
          next: response.data.next,
          paginationCount: Math.ceil(response.data.count / 10),
          Items: response.data.results.items,
          totalSections: response.data.results.length,
          isLoading: false,
        });
        console.log(response.data.results);
      })
      .then((err) => {
        console.log(err);
      });
  };
  oneditCmsUser = (data) => {
    this.toggleCmsUserListEditModal();
    this.setState({ editedData: data });
    console.log("edited ", data);
  };
  onDeleteCmsUser = (id, name) => {
    removeCmsUser(id)
      .then((res) => {
        toast.success(`${name} deleted successfully`);
        this.fetchCmsUser(this.state.currentPage);
      })
      .catch((err) => {
        toast.err(`${name} deleted Failed`);
        console.log(err);
      });
  };
  toggleCmsUserListEditModal = () => {
    this.setState({ editCmsUser: !this.state.editCmsUser });
  };
  render() {
    return (
      <div>
        {this.state.ViewAuthentication ? (
          <>
            <Container>
              <h1>{"All Cms User"}</h1>
              <Container>
                <Modal
                  size="xl"
                  show={this.state.editCmsUser}
                  onHide={this.toggleCmsUserListEditModal}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Cms User </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <CreateUser
                      editedData={this.state.editedData}
                      update={true}
                      toggleCmsUserListEditModal={
                        this.toggleCmsUserListEditModal
                      }
                    />
                  </Modal.Body>
                </Modal>
              </Container>
              <Row>
                <Col className="centerAlign">
                  <Table responsive hover>
                    <thead>
                      <tr key="header">
                        <th scope="col">Sr.no</th>
                        <th scope="col">First Name </th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Group</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.CmsUserData.map((item) => (
                        //   <div key={item.id}>
                        //     <h1>{item.firstName}</h1>
                        //     <p>{item.lastName}</p>
                        //     <p>{item.groups[0].name}</p>
                        //   </div>

                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.firstName}</td>
                          <td>{item.lastName}</td>
                          <td>{item.groups[0].name}</td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={() => this.oneditCmsUser(item)}
                              disabled={this.state.ChangeAuthentication}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="danger"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    `Are you sure you wish to delete User ${item.firstName}?`
                                  )
                                )
                                  this.onDeleteCmsUser(
                                    item.uniqueSlug,
                                    item.firstName
                                  );
                              }}
                              disabled={this.state.deletAuthentication}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Container>

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={this.state.paginationCount}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={this.state.currentPage - 1}
            ></ReactPaginate>
          </>
        ) : (
          <NotAuthenticatedMsg />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  UserPermission: state.User.permission,
});

export default connect(mapStateToProps)(AllCmsUser);
