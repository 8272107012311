import http from "../http-common";
import auth from "./authentication";
const baseURL = "https://staging.samortech.com";
// let auth ={username: "aawazadmin", password:"aawazadmin"}

const createRss = (data) => {
  return http.post(`${baseURL}/rss/rss-updates/`, data);
};
const RssShow = () => {
  return http.get(`${baseURL}/rss/show-details/`);
};
const RssList = (page = 1) => {
  if (page == "1") {
    return http.get(`${baseURL}/rss/rss-updates/?limit=10`);
  } else {
    return http.get(`${baseURL}/rss/rss-updates/?limit=10&offset=${page - 1}0`);
  }
};
const UpdateRss = (id, data) => {
  return http.patch(`${baseURL}/rss/rss-updates/${id}/`, data);
};
const deleteRss = (id) => {
  return http.delete(`${baseURL}/rss/rss-updates/${id}/`);
};
const LiveRss = () => {
  return http.get(`${baseURL}/api/shows/update_rss_feed/`);
};

export { createRss, RssShow, UpdateRss, deleteRss, RssList, LiveRss };
