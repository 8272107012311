import React, { Component } from "react";
import {
  Button,
  Form,
  Col,
  Figure,
  Card,
  Accordion,
  Alert,
  Modal,
  Container,
  Row,
  ListGroup,
} from "react-bootstrap";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getShow, baseURL, updateShow } from "../../../services/serviceShows";
import {
  removeEpisode,
  sequenceEpisode,
} from "../../../services/serviceEpisodes";
import moment from "moment";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import auth from "../../../services/authentication";
import { Pencil, Trash } from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotAuthenticatedMsg from "../../NotAuthenticatedMsg";
import { connect } from "react-redux";

class EditShowEpisode extends Component {
  state = {
    show_slug: "",
    isLoading: false,
    editedShow: {},
    title: "",
    bannerImageView: "",
    featuredImageView: "",

    audio_file: "",
    dateTime: new Date(),
    published_on: `${moment(new Date()).format()}`,
    episode_no: 0,
    weight: 0,
    sequence: 0,
    short_description: "",
    description: "",
    short_url: "",
    banner_image: "",
    isEdit: false,
    isSequenceDisabled: true,
    disabled: false,
    alt_title: "",
    oldest: "-sequence",
    eac3_audio_url: "",
    Ac4_audio_url: "",
    eac3_audio_file: "",
    ac4_audio_file: "",
    is_dolby_atmos: false,
    ViewAuthentication: false,
    ChangeAuthentication: true,
    deletAuthentication: true,
    AddAuthentication: false,
  };

  UNSAFE_componentWillMount = () => {
    //Triggeres first
    console.log("will mount triggered");
    // let search = window.location.search;
    // let params = new URLSearchParams(search);
    // let unique = params.get('unique');
    // let filter = params.get('filter');
    console.log("filter from epi", this.props.match.params.unique);
    this.setState({
      isLoading: true,
      show_slug: this.props.match.params.unique,
      filter: this.props.match.params.filter,
    });
  };

  handleRLDDChange = (newItems) => {
    let data = this.state.editedShow;
    let newArr = newItems;
    // console.log('sorted arr: ', data.episodes)
    let newEpi = newArr.map((item, index) => {
      return { ...item, sequence: index + 1 };
    });
    data.episodes = newEpi;
    this.setState({ editedShow: data, isSequenceDisabled: false });
  };

  inverseSequence = () => {
    let show = this.state.editedShow;
    let episodes = this.state.editedShow.episodes;
    let revEpisodes = episodes.reverse();
    // console.log("reversed seq: ", newEpisodes)
    let newEpisodes = revEpisodes.map((item, index) => {
      return { ...item, sequence: index + 1 };
    });
    show.episodes = newEpisodes;
    this.setState({ editedShow: show, isSequenceDisabled: false });
  };

  invertNewEpisodeSequence = () => {
    if (this.state.sequence !== 1) {
      this.setState({ sequence: 1 });
    } else {
      this.setState({ sequence: this.state.editedShow.episodes.length + 1 });
    }
  };

  saveSequence = () => {
    let epiSlugs = this.state.editedShow.episodes
      .map((item) => item.uniqueSlug)
      .join();
    // console.log(epiSlugs)
    // console.log(this.state.editedShow.episodes)
    sequenceEpisode(this.state.show_slug, epiSlugs)
      .then(() => {
        this.setState({ isSequenceDisabled: true }, () =>
          toast.success("Sequence updated successfully!")
        );
      })
      .catch((err) => {
        toast.error("Couldn't update the sequence at the moment!");
        console.log("Episode sequence error: ", err);
      });
  };
  fetchshowData = async () => {
    await getShow(this.state.show_slug, this.state.filter)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          console.log(data);
          let episodesWithId = data.episodes.map((epi, index) => {
            return { ...epi, id: index + 1 };
          });
          data.episodes = episodesWithId;
          // console.log("data: ",dataWithId)
          this.setState({
            editedShow: data,
            isLoading: false,
            sequence: data.episodes.length + 1,
          });
        }
      })
      .catch((res) => {
        console.log(res);
        this.setState({ isLoading: false });
      });
  };
  componentDidMount = () => {
    this.setState({ isLoading: false });
    let EpisodePermissionData = this.props.EpisodePermission.filter(
      (data) => data.name === "episode"
    );
    EpisodePermissionData[0].subPermissions.map((data) => {
      if (data.method === "add" && data.isPermitted) {
        this.setState({ AddAuthentication: true });
        this.setState({ isLoading: true });
        this.fetchshowData();
      } else if (data.method === "view" && data.isPermitted) {
        this.setState({ ViewAuthentication: true });
        this.setState({ isLoading: true });
        this.fetchshowData();
      } else if (data.method === "change" && data.isPermitted) {
        this.setState({ ChangeAuthentication: false });
        this.fetchshowData()
      } else if (data.method === "delete" && data.isPermitted) {
        this.setState({ deletAuthentication: false });
      }
    });
  };
  OldestSequence = () => {
    let data = {
      episode_ordering: "-sequence",
    };
    let formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      switch (key) {
        case "oldest":
          formData.append("episode_ordering", value);
          console.log("datata", value);
          break;
        default:
          formData.append(key, value);
      }
    }
    axios({
      url: `${baseURL}/shows/${this.state.show_slug}/`,
      method: "PATCH",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      auth: auth,
    })
      .then((response) => {
        // console.log("OldestSequence", response)
        this.componentDidMount();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  LatestSequence = () => {
    let data = {
      episode_ordering: "sequence",
    };
    let formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      switch (key) {
        case "oldest":
          formData.append("episode_ordering", value);
          break;
        default:
          formData.append(key, value);
      }
    }
    axios({
      url: `${baseURL}/shows/${this.state.show_slug}/`,
      method: "PATCH",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      auth: auth,
    })
      .then((response) => {
        // console.log("LatestSequence", response)
        this.componentDidMount();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  clearState = () => {
    // for (let [key, value] of Object.entries(this.state)) {
    //     switch (key) {

    //     }
    // }
    this.setState({ sequence: this.state.editedShow.episodes.length + 1 });
  };

  handleDateChange = (date) => {
    this.setState({
      published_on: moment(date).format(),
      dateTime: date,
    });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onFeaturedImageChange = (event) => {
    this.setState({ featuredImageView: undefined, featured_image: undefined });
    if (event.target.files[0]) {
      let reader = new FileReader();
      this.setState({ featured_image: event.target.files[0] });
      reader.onload = (e) => {
        this.setState({ featuredImageView: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  onBannerImageChange = (event) => {
    this.setState({ bannerImageView: undefined, banner_image: undefined });
    if (event.target.files[0]) {
      let reader = new FileReader();
      this.setState({ banner_image: event.target.files[0] });
      reader.onload = (e) => {
        this.setState({ bannerImageView: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  onAudioFileChange = (event) => {
    this.setState({
      audio_file: event.target.files[0],
      disabled: true,
      audio_url: "",
      episode_length: "",
    });
  };
  onEac3AudioFileChange = (event) => {
    this.setState({
      eac3_audio_file: event.target.files[0],
      eac3_audio_url: "",
    });
  };
  onAc4AudioFileChange = (event) => {
    this.setState({
      ac4_audio_file: event.target.files[0],
      Ac4_audio_url: "",
    });
  };
  handelDolbyAtoms = () => {
    this.setState({ is_dolby_atmos: !this.state.is_dolby_atmos });
  };
  submitHandler = (e) => {
    e.preventDefault();
    // console.log(this.state)
    this.setState({ isLoading: true });
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "weight":
        case "sequence":
        case "episode_no":
          formData.append(`${key}`, parseInt(value));
          break;
        case "bannerImageView":
        case "featuredImageView":
        case "dateTime":
        case "editedShow":
        case "feature_image":
        case "isSequenceDisabled":
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    // for (var [key, value] of formData.entries()) {
    //     if (value === '' || value === undefined || value === {}) {
    //         this.setState({ isLoading: false })
    //         // return toast.error(`${key} is required!`)
    //     }

    // }
    axios({
      url: `${baseURL}/episodes/`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      auth: auth,
    })
      .then((response) => {
        // console.log(response)
        if (response.status === 201) {
          toast.success(
            `New Episode ${response.data.title} created successfully. Click refresh button to view new data.`
          );
          // let show = this.state.editedShow
          // let episodes = show.episodes
          // episodes.push(response.data)
          // show.episodes = episodes
          this.setState({ isLoading: false });
          // window.location.reload(false);
          this.setState({
            title: "",
            bannerImageView: "",
            featuredImageView: "",
            // featured_image: "",
            audio_file: "",
            episode_no: 0,
            weight: 0,
            short_description: "",
            description: "",
            short_url: "",
            banner_image: "",
            banner_image_url: "",
            featured_image_url: "",
            image_url: "",
            audio_url: "",
            episode_length: "",
            alt_title: "",
            eac3_audio_file: "",
            eac3_audio_url: "",
            ac4_audio_file: "",
            Ac4_audio_url: "",
          });
          this.fetchshowData();
        }
        // if (response.status === 400) {
        //     toast.error('Something went wrong, please try again')
        //     this.setState({ isLoading: false })
        // }
      })
      .catch((error) => {
        console.log(error.response.status);
        if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key}:${value}`;
          }
          console.log(err);
          this.setState({ isLoading: false });
          return toast.error(`${err}`);
        }
        // toast.error('Adding new episode failed!')
        // this.setState({ isLoading: false })
      });
  };

  deleteEpisode = (id) => {
    removeEpisode(id)
      .then((res) => {
        if (res.status === 204) {
          let show = this.state.editedShow;
          show.episodes = show.episodes.filter((epi) => epi.uniqueSlug !== id);
          this.setState({ editedShow: show }, () =>
            toast.success("Episode deleted successfully!")
          );
        }
        // console.log(res)
      })
      .catch((err) => {
        console.log(err);
        toast.error("Episode delete failed!");
      });
  };

  updateEpisode = (data) => {
    this.setState(() => {
      return {
        isLoading: false,
        isEdit: true,
        title: data.title,

        episode_no: data.episodeNo,
        weight: data.weight,
        sequence: data.sequence,
        short_description: data.shortDescription,
        description: data.description,
        short_url: data.shortUrl,
        featured_image_url: data.featuredImageUrl,
        banner_image_url: data.bannerImageUrl,
        alt_title: data.altTitle,
        is_dolby_atmos: data.isDolbyAtmos,
        currentData: {
          featured_image: data.featuredImageUrl,
          banner_image: data.bannerImageUrl,
          audio_file: data.audioFileUrl,
          episode_slug: data.uniqueSlug,
          eac3_audio_file: data.eac3AudioUrl,
          ac4_audio_file: data.ac4AudioUrl,
        },
      };
    });
  };

  handleModalClose = () => {
    this.setState({
      isLoading: false,
      isEdit: false,
      title: "",
      episode_no: "",
      weight: "",
      sequence: "",
      short_description: "",
      description: "",
      short_url: "",
      featured_image_url: "",
      banner_image_url: "",
      image_url: "",
      audio_url: "",
      episode_length: "",
      alt_title: "",
      is_dolby_atmos: "",
      currentData: {
        featured_image: undefined,
        banner_image: undefined,
        audio_file: undefined,
        episode_slug: "",
        eac3_audio_file: undefined,
        ac4_audio_file: undefined,
      },
    });
  };

  updateHandler = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let formData = new FormData();
    if (this.state.featured_image)
      formData.append("featured_image", this.state.featured_image);
    if (this.state.banner_image)
      formData.append("banner_image", this.state.banner_image);
    if (this.state.audio_file)
      formData.append("audio_file", this.state.audio_file);
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "weight":
        case "sequence":
        case "episode_no":
          formData.append(`${key}`, parseInt(value));
          break;
        case "bannerImageView":
        case "featuredImageView":
        case "featured_image":
        case "banner_image":
        case "audio_file":
        case "currentData":
        case "editedShow":
        case "isLoading":
        case "isEdit":
        case "show_slug":
        case "dateTime":
        case "published_on":
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    // for (var [key, value] of formData.entries()) {
    //     if (value === '' || value === undefined || value === {}) {

    //         // return toast.error(`${key} is required!`)
    //     }
    //     // console.log(key + ': ' + value);
    // }

    axios({
      url: `${baseURL}/episodes/${this.state.currentData.episode_slug}/`,
      method: "PATCH",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      auth: auth,
    })
      .then(async (response) => {
        if (response.status === 200) {
          toast.success(
            `Episode "${response.data.title}" updated successfully.`
          );
          this.setState({
            isLoading: false,
            isEdit: false,
            title: "",
            bannerImageView: "",
            featuredImageView: "",
            // featured_image: "",
            audio_file: "",
            episode_no: 0,
            weight: 0,
            short_description: "",
            description: "",
            short_url: "",
            banner_image: "",
            banner_image_url: "",
            featured_image_url: "",
            image_url: "",
            audio_url: "",
            episode_length: "",
            alt_title: "",
          });
          this.fetchshowData();

          // window.location.reload(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key}: ${value[0]}. `;
          }
          toast.error(err);
          this.setState({ isLoading: false });
        }
        console.log(error);
      });
  };

  render() {
    console.log("state render", this.state);
    const {
      title,
      weight,
      sequence,
      short_description,
      description,
      audio_url,
      short_url,
      episode_no,
      editedShow,
      featured_image_url,
      banner_image_url,
      image_url,
      episode_length,
      alt_title,
      eac3_audio_url,
      Ac4_audio_url,
      is_dolby_atmos,
    } = this.state;
    return (
      <Container fluid>
        {this.state.isEdit ? (
          <Modal
            size="xl"
            show={this.state.isEdit}
            onHide={this.handleModalClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Episode</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={this.updateHandler}>
                <Form.Group>
                  <Form.Label>
                    Title<span style={{ color: "red" }}>*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Episode Title"
                    name="title"
                    value={title}
                    onChange={this.changeHandler}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>
                    Alternative Tilte: <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Text className="text-muted">
                    "If the title is in a language other than English, change it
                    to eg: "लल्लन चला अमेरिका" by writing "lallan chala America"
                  </Form.Text>
                  <Form.Control
                    type="text"
                    placeholder="alternative title"
                    name="alt_title"
                    value={alt_title}
                    onChange={this.changeHandler}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Description </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="4"
                    placeholder="Episode Description"
                    name="description"
                    value={description}
                    onChange={this.changeHandler}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Short Description </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="2"
                    placeholder="Episode Short Description"
                    name="short_description"
                    value={short_description}
                    onChange={this.changeHandler}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>
                    Short URL<span style={{ color: "red" }}>*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Episode Short URL"
                    name="short_url"
                    value={short_url}
                    onChange={this.changeHandler}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Row>
                    <Col>
                      <Form.Label>
                        Current Audio File
                        <span style={{ color: "red" }}>*</span>{" "}
                      </Form.Label>
                      <br></br>
                      {this.state.currentData.audio_file ? (
                        <audio
                          controls
                          src={this.state.currentData.audio_file}
                        />
                      ) : (
                        <p>Audio file not present</p>
                      )}
                    </Col>

                    <Col>
                      <Form.Label>
                        New Audio File<span style={{ color: "red" }}>*</span>{" "}
                      </Form.Label>
                      <br></br>
                      <Form.File
                        name="audio_file"
                        onChange={this.onAudioFileChange}
                        accept=".mp3"
                      />
                      <Form.Text className="text-muted">
                        "Supported file type: Only .mp3 .wav files are
                        supported"
                      </Form.Text>
                    </Col>
                    <Col>
                      <Form.Label>Audio URL: </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Audio URL"
                        name="audio_url"
                        disabled={this.state.disabled}
                        onChange={this.changeHandler}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Audio Length :</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Audio Length"
                        name="episode_length"
                        disabled={this.state.disabled}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </Form.Row>
                </Form.Group>

                <Form.Group>
                  <Form.Label>is Dolby Atmos :</Form.Label>
                  <Form.Control
                    inline
                    style={{
                      width: "14px",
                      height: "27px",
                      marginLeft: "12px",
                    }}
                    name="is_dolby_atmos"
                    type="checkbox"
                    checked={is_dolby_atmos}
                    onClick={this.handelDolbyAtoms}
                  />
                </Form.Group>
                {this.state.is_dolby_atmos ? (
                  <>
                    <Form.Group>
                      <Form.Row>
                        <Col>
                          <Form.Label>
                            Current Eac3 Audio File
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <br></br>
                          {this.state.currentData.eac3_audio_file ? (
                            <audio
                              controls
                              src={this.state.currentData.eac3_audio_file}
                            />
                          ) : (
                            <p>Audio file not present</p>
                          )}
                        </Col>

                        <Col>
                          <Form.Label>
                            New Eac3 Audio File
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <br></br>
                          <Form.File
                            name="eac3_audio_file"
                            onChange={this.onEac3AudioFileChange}
                            accept=".mp4"
                          />
                          <Form.Text className="text-muted">
                            "Supported file type: Only .mp4 .wav files are
                            supported"
                          </Form.Text>
                        </Col>
                        <Col>
                          {/* <Form.Label>Audio URL: </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Audio URL"
                        name="eac3_audio_url"
                        disabled={this.state.disabled}
                        onChange={this.changeHandler}
                      /> */}
                        </Col>
                        <Col>
                          {/* <Form.Label>Audio Length :</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Audio Length"
                        name="episode_length"
                        disabled={this.state.disabled}
                        onChange={this.changeHandler}
                      /> */}
                        </Col>
                      </Form.Row>
                    </Form.Group>

                    <Form.Group>
                      <Form.Row>
                        <Col>
                          <Form.Label>
                            Current Ac4 Audio File
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <br></br>
                          {this.state.currentData.ac4_audio_file ? (
                            <audio
                              controls
                              src={this.state.currentData.ac4_audio_file}
                            />
                          ) : (
                            <p>Audio file not present</p>
                          )}
                        </Col>

                        <Col>
                          <Form.Label>
                            New Ac4 Audio File
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <br></br>
                          <Form.File
                            name="ac4_audio_file"
                            onChange={this.onAc4AudioFileChange}
                            accept=".mp4"
                          />
                          <Form.Text className="text-muted">
                            "Supported file type: Only .mp4 .wav files are
                            supported"
                          </Form.Text>
                        </Col>
                        <Col>
                          {/* <Form.Label>Audio URL: </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Audio URL"
                        name="Ac4_audio_url"
                        disabled={this.state.disabled}
                        onChange={this.changeHandler}
                      /> */}
                        </Col>
                        <Col>
                          {/* <Form.Label>Audio Length :</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Audio Length"
                        name="episode_length"
                        disabled={this.state.disabled}
                        onChange={this.changeHandler}
                      /> */}
                        </Col>
                      </Form.Row>
                    </Form.Group>
                  </>
                ) : null}
                <Form.Group>
                  <Form.Row>
                    <Col>
                      <Form.Label>
                        Current Featured Image :
                        <span style={{ color: "red" }}>*</span>{" "}
                      </Form.Label>
                      <br></br>
                      {this.state.currentData.featured_image ? (
                        <Figure>
                          <Figure.Image
                            src={this.state.currentData.featured_image}
                            thumbnail
                            width={171}
                            height={180}
                          />
                        </Figure>
                      ) : (
                        <p>No image selected</p>
                      )}
                    </Col>

                    <Col>
                      <Form.Label>
                        New Featured Image
                        <span style={{ color: "red" }}>*</span>{" "}
                      </Form.Label>
                      <br></br>
                      {this.state.featuredImageView ? (
                        <Figure>
                          <Figure.Image
                            src={this.state.featuredImageView}
                            thumbnail
                            width={171}
                            height={180}
                          />
                        </Figure>
                      ) : (
                        <p>No image selected</p>
                      )}
                      <Form.File
                        type="file"
                        name="featured_image"
                        onChange={this.onFeaturedImageChange}
                      />
                      <Form.Text className="text-muted">
                        "Supported file type: only png, .jpg, .webp,svg file
                        formats are supported"
                      </Form.Text>
                    </Col>
                    <Col>
                      <Form.Label>Featured Image URL: </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Featured Image URL"
                        name="featured_image_url"
                        value={featured_image_url}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <Form.Label>
                        Current Banner Image :
                        <span style={{ color: "red" }}>*</span>{" "}
                      </Form.Label>
                      <br></br>
                      {this.state.currentData.banner_image ? (
                        <Figure>
                          <Figure.Image
                            src={this.state.currentData.banner_image}
                            thumbnail
                            width={171}
                            height={180}
                          />
                        </Figure>
                      ) : (
                        <p>No image selected</p>
                      )}
                    </Col>
                    <Col>
                      <Form.Label>
                        New Banner Image<span style={{ color: "red" }}>*</span>{" "}
                      </Form.Label>
                      <br></br>
                      {this.state.bannerImageView ? (
                        <Figure>
                          <Figure.Image
                            src={this.state.bannerImageView}
                            thumbnail
                            width={171}
                            height={180}
                          />
                        </Figure>
                      ) : (
                        <p>No image selected</p>
                      )}
                      <Form.File
                        type="file"
                        name="featured_image"
                        onChange={this.onBannerImageChange}
                      />
                      <Form.Text className="text-muted">
                        "Supported file type: only png, .jpg, .webp,svg file
                        formats are supported"
                      </Form.Text>
                    </Col>
                    <Col>
                      <Form.Label>Banner Image URL: </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Banner Image URL"
                        name="banner_image_url"
                        value={banner_image_url}
                        onChange={this.changeHandler}
                      />
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <Form.Label>Image URL: </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Image URL"
                        name="image_url"
                        value={image_url}
                        onChange={this.changeHandler}
                      />{" "}
                      <br />
                    </Col>
                  </Form.Row>
                </Form.Group>

                <Form.Group>
                  <Form.Label>
                    Episode Number<span style={{ color: "red" }}>*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    placeholder="Episode No"
                    name="episode_no"
                    value={episode_no}
                    onChange={this.changeHandler}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>
                    Weight<span style={{ color: "red" }}>*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min="0"
                    placeholder="Weight"
                    name="weight"
                    value={weight}
                    onChange={this.changeHandler}
                  />
                </Form.Group>

                {/* <Form.Group>
                                    <Form.Label>Sequence<span style={{ color: "red" }}>*</span> </Form.Label>
                                    <Form.Control disabled={true} type="number" min="0" placeholder="Sequence" name="sequence" value={sequence} onChange={this.changeHandler} />
                                </Form.Group> */}
                {this.state.isLoading === true ? (
                  <Loader
                    type="ThreeDots"
                    color="#eb1163"
                    height={100}
                    width={50}
                  />
                ) : (
                  <Button type="submit" variant="info">
                    Update
                  </Button>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleModalClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}

        {!this.state.isLoading ? (
          <Row>
            <Col>
              <ToastContainer
                autoClose={5000}
                hideProgressBar={false}
                position="top-center"
              />
              {this.state.AddAuthentication ? (
                <>
                  <Form onSubmit={this.submitHandler}>
                    <Form.Group>
                      <Form.Row>
                        <Button
                          variant="warning"
                          onClick={this.componentDidMount}
                        >
                          Refresh Episodes
                        </Button>
                      </Form.Row>
                      <br></br>

                      <Accordion id="newEpisode">
                        <Card>
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey="0"
                              onClick={this.clearState}
                            >
                              Add Episode
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse
                            itemID={"newEpisode1"}
                            eventKey="0"
                          >
                            <Card.Body>
                              <Form.Group>
                                <Form.Label>
                                  Title<span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Episode Title"
                                  name="title"
                                  value={title}
                                  onChange={this.changeHandler}
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>
                                  Alternative Tilte:
                                  <span style={{ color: "red" }}>*</span>
                                </Form.Label>
                                <Form.Text className="text-muted">
                                  "If the title is in a language other than
                                  English, change it to eg: "लल्लन चला अमेरिका"
                                  by writing "lallan chala America"
                                </Form.Text>
                                <Form.Control
                                  type="text"
                                  placeholder="alternative title"
                                  name="alt_title"
                                  value={alt_title}
                                  onChange={this.changeHandler}
                                  required
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>
                                  Description
                                  <span style={{ color: "red" }}>*</span>{" "}
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows="4"
                                  placeholder="Episode Description"
                                  name="description"
                                  value={description}
                                  onChange={this.changeHandler}
                                />
                              </Form.Group>

                              <Form.Group>
                                <Form.Label>
                                  Short Description
                                  <span style={{ color: "red" }}>*</span>{" "}
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows="2"
                                  placeholder="Episode Short Description"
                                  name="short_description"
                                  value={short_description}
                                  onChange={this.changeHandler}
                                />
                              </Form.Group>

                              <Form.Group>
                                <Form.Label>
                                  Short URL
                                  <span style={{ color: "red" }}>*</span>{" "}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Episode Short URL"
                                  name="short_url"
                                  value={short_url}
                                  disabled
                                  onChange={this.changeHandler}
                                />
                              </Form.Group>

                              <Form.Group>
                                <Form.Row>
                                  <Col>
                                    <Form.Label>
                                      Published On
                                      <span style={{ color: "red" }}>
                                        *
                                      </span>{" "}
                                    </Form.Label>
                                    <br></br>
                                    <DatePicker
                                      name="published_on"
                                      selected={this.state.dateTime}
                                      onChange={this.handleDateChange}
                                      showTimeSelect
                                      timeFormat="HH:mm"
                                      timeIntervals={15}
                                      timeCaption="time"
                                      dateFormat="MMMM d, yyyy h:mm aa"
                                    />
                                  </Col>
                                </Form.Row>
                              </Form.Group>

                              <Form.Group>
                                <Form.Row>
                                  <Col>
                                    <Form.Label>
                                      Featured Image Upload :{" "}
                                    </Form.Label>
                                    <br></br>
                                    {this.state.featuredImageView ? (
                                      <Figure>
                                        <Figure.Image
                                          src={this.state.featuredImageView}
                                          thumbnail
                                          width={171}
                                          height={180}
                                        />
                                      </Figure>
                                    ) : (
                                      <p>No image selected</p>
                                    )}
                                    <Form.File
                                      type="file"
                                      name="featured_image"
                                      onChange={this.onFeaturedImageChange}
                                    />
                                  </Col>
                                  <Col>
                                    <Form.Label>
                                      Featured Image URL:{" "}
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Featured Image URL"
                                      name="featured_image_url"
                                      value={this.state.featured_image_url}
                                      onChange={this.changeHandler}
                                    />
                                  </Col>
                                </Form.Row>
                              </Form.Group>
                              <Form.Group>
                                <Form.Row>
                                  <Col>
                                    <Form.Label>
                                      Banner Image Upload :{" "}
                                    </Form.Label>
                                    <br></br>
                                    {this.state.bannerImageView ? (
                                      <Figure>
                                        <Figure.Image
                                          src={this.state.bannerImageView}
                                          thumbnail
                                          width={171}
                                          height={180}
                                        />
                                      </Figure>
                                    ) : (
                                      <p>No image selected</p>
                                    )}
                                    <Form.File
                                      type="file"
                                      name="banner_image"
                                      onChange={this.onBannerImageChange}
                                    />
                                  </Col>
                                  <Col>
                                    <Form.Label>Banner Image URL: </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Banner Image URL"
                                      name="banner_image_url"
                                      value={banner_image_url}
                                      onChange={this.changeHandler}
                                    />
                                  </Col>
                                </Form.Row>
                              </Form.Group>
                              <Form.Group>
                                <Form.Label> Image URL: </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Image URL"
                                  name="image_url"
                                  value={image_url}
                                  onChange={this.changeHandler}
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Row>
                                  <Col>
                                    <Form.Label>
                                      Audio File Upload
                                      <span style={{ color: "red" }}>
                                        *
                                      </span>{" "}
                                    </Form.Label>
                                    <br></br>
                                    <Form.File
                                      name="audio_file"
                                      onChange={this.onAudioFileChange}
                                      accept=".mp3"
                                    />
                                  </Col>
                                  <Col>
                                    <Form.Label>Audio URL: </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Audio URL"
                                      name="audio_url"
                                      value={audio_url}
                                      disabled={this.state.disabled}
                                      onChange={this.changeHandler}
                                    />
                                  </Col>
                                  <Col>
                                    <Form.Label>Audio Length :</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Audio Length"
                                      name="episode_length"
                                      value={episode_length}
                                      disabled={this.state.disabled}
                                      onChange={this.changeHandler}
                                    />
                                  </Col>
                                </Form.Row>
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>is Dolby Atmos :</Form.Label>
                                <Form.Control
                                  inline
                                  style={{
                                    width: "14px",
                                    height: "27px",
                                    marginLeft: "12px",
                                  }}
                                  name="is_dolby_atmos"
                                  type="checkbox"
                                  checked={is_dolby_atmos}
                                  onClick={this.handelDolbyAtoms}
                                />
                              </Form.Group>
                              {this.state.is_dolby_atmos ? (
                                <>
                                  <Form.Group>
                                    <Form.Row>
                                      <Col>
                                        <Form.Label>
                                          eac3_audio_file Upload
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>{" "}
                                        </Form.Label>
                                        <br></br>
                                        <Form.File
                                          name="eac3_audio_file"
                                          onChange={this.onEac3AudioFileChange}
                                          accept=".mp4"
                                          required
                                        />
                                      </Col>
                                      <Col>
                                        {/* <Form.Label>eac3 Audio URL:</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="eac3 Audio URL"
                                      name="eac3_audio_url"
                                      value={eac3_audio_url}
                                      disabled={this.state.disabled}
                                      onChange={this.changeHandler}
                                      readOnly
                                    /> */}
                                      </Col>
                                      <Col></Col>
                                    </Form.Row>
                                  </Form.Group>
                                  <Form.Group>
                                    <Form.Row>
                                      <Col>
                                        <Form.Label>
                                          Ac4 audio file Upload
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>{" "}
                                        </Form.Label>
                                        <br></br>
                                        <Form.File
                                          name="ac4_audio_file"
                                          onChange={this.onAc4AudioFileChange}
                                          accept=".mp4"
                                          required
                                        />
                                      </Col>
                                      <Col>
                                        {/* <Form.Label>ac4 Audio URL:</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="eac3 Audio URL"
                                      name="Ac4_audio_url"
                                      readOnly
                                      value={Ac4_audio_url}
                                      disabled={this.state.disabled}
                                      onChange={this.changeHandler}
                                    /> */}
                                      </Col>
                                      <Col></Col>
                                    </Form.Row>
                                  </Form.Group>
                                </>
                              ) : null}
                              <Form.Group>
                                <Form.Label>
                                  Episode Number
                                  <span style={{ color: "red" }}>*</span>{" "}
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  min="0"
                                  placeholder="Episode No"
                                  name="episode_no"
                                  value={episode_no}
                                  onChange={this.changeHandler}
                                />
                              </Form.Group>

                              <Form.Group>
                                <Form.Label>
                                  Weight<span style={{ color: "red" }}>*</span>{" "}
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  min="0"
                                  placeholder="Weight"
                                  name="weight"
                                  value={weight}
                                  onChange={this.changeHandler}
                                />
                              </Form.Group>
                              <Row>
                                <Col>
                                  <Form.Group>
                                    <Form.Label>
                                      Sequence
                                      <span style={{ color: "red" }}>
                                        *
                                      </span>{" "}
                                    </Form.Label>
                                    <Form.Control
                                      disabled={true}
                                      type="number"
                                      min="0"
                                      placeholder="Sequence"
                                      name="sequence"
                                      value={sequence}
                                      onChange={this.changeHandler}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group controlId="invertCheckbox">
                                    <Form.Check
                                      type="checkbox"
                                      label="Invert default sequencing"
                                      onClick={this.invertNewEpisodeSequence}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>

                              <Button type="submit" variant="info">
                                Publish
                              </Button>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </Form.Group>
                  </Form>
                </>
              ) : (
                <NotAuthenticatedMsg />
              )}
              {this.state.ViewAuthentication ? (
                <>
                  {editedShow.episodes.length === 0 ? (
                    <Alert variant="danger">
                      No episodes found. Please Add Episode to the show.
                    </Alert>
                  ) : (
                    <div>
                      <Card>
                        <Card.Body>
                          <h5>Show name: {editedShow.title}</h5>
                          <Button
                            variant="info"
                            disabled={this.state.isSequenceDisabled}
                            onClick={this.saveSequence}
                            style={{ float: "right" }}
                          >
                            Save sequence
                          </Button>
                          &nbsp;
                          <Button variant="info" onClick={this.inverseSequence}>
                            Inverse Sequence
                          </Button>
                          &nbsp;{" "}
                          <Button variant="info" onClick={this.LatestSequence}>
                            Latest episodes
                          </Button>
                          &nbsp;
                          <Button variant="info" onClick={this.OldestSequence}>
                            Oldest episodes
                          </Button>
                          <br />
                          <br />
                          <Alert variant={"info"}>
                            <small>
                              <b>Tip:</b> Drag and drop episodes to change their
                              sequence
                            </small>
                          </Alert>
                          <h6>Total episodes: {editedShow.episodes.length}</h6>
                          <ListGroup>
                            <ListGroup.Item variant="secondary">
                              <Container fluid>
                                <Row style={{ textAlign: "center" }}>
                                  <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                                    <strong>Ep No.</strong>
                                  </Col>
                                  <Col className>
                                    <strong>Name</strong>
                                  </Col>
                                  <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                                    <strong>Seq No.</strong>
                                  </Col>
                                  <Col className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                    <strong>Actions</strong>
                                  </Col>
                                </Row>
                              </Container>
                            </ListGroup.Item>
                            <RLDD
                              id
                              items={editedShow.episodes}
                              itemRenderer={(data) => {
                                return (
                                  <ListGroup.Item action>
                                    <Container fluid>
                                      <Row style={{ textAlign: "center" }}>
                                        <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                                          <strong>{data.episodeNo}</strong>
                                        </Col>
                                        <Col>
                                          <strong>{data.title}</strong>
                                        </Col>
                                        <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                                          <strong>{data.sequence}</strong>
                                        </Col>
                                        <Col className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                          <Button
                                            variant="outline-danger"
                                            onClick={() => {
                                              if (
                                                window.confirm(
                                                  `Are you sure you wish to delete episode "${data.title}"?`
                                                )
                                              )
                                                this.deleteEpisode(
                                                  data.uniqueSlug
                                                );
                                            }}
                                            disabled={
                                              this.state.deletAuthentication
                                            }
                                          >
                                            <Trash />
                                          </Button>{" "}
                                          <Button
                                            variant="outline-info"
                                            onClick={() => {
                                              this.updateEpisode(data);
                                            }}
                                            disabled={
                                              this.state.ChangeAuthentication
                                            }
                                          >
                                            <Pencil />
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Container>
                                  </ListGroup.Item>
                                );
                              }}
                              onChange={this.handleRLDDChange}
                            />
                          </ListGroup>
                        </Card.Body>
                      </Card>
                    </div>
                  )}{" "}
                </>
              ) : (
                <NotAuthenticatedMsg />
              )}
            </Col>
          </Row>
        ) : (
          <Loader type="ThreeDots" color="#eb1163" height={100} width={50} />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  EpisodePermission: state.User.permission,
});

export default connect(mapStateToProps)(EditShowEpisode);
