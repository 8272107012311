import React, { Component } from "react";
import { Col, Form, Button } from "react-bootstrap";
import {
  createGroup,
  getPermission,
  updateGroup,
  GetGroupById,
} from "../../../services/serviceGroup";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import { connect } from "react-redux";
import NotAuthenticatedMsg from "../../NotAuthenticatedMsg";

export class CreateGroup extends Component {
  state = {
    name: "",
    permissions_id_list: "",
    Permissions: [],
    level: "",
    AddAuthentication: false,
  };
  componentDidMount = () => {
    let GroupPermissionData = this.props.GroupPermission.filter(
      (data) => data.name === "group"
    );
    GroupPermissionData[0].subPermissions.map((data) => {
      if (data.method === "add" && data.isPermitted) {
        this.setState({ AddAuthentication: true });
        if (this.props.isEdit) {
          GetGroupById(this.props.data.id)
            .then((res) => {
              let showOptions = [];
              res.data.permissions.forEach((data) => {
                showOptions.push({
                  value: data.id,
                  label: data.name,
                });
              });
              this.setState({
                name: res.data.name,
                permissions_id_list: showOptions,
                level: res.data.level,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
        getPermission()
          .then((res) => {
            let newPermission = [];
            res.data.forEach((data) => {
              newPermission.push({ value: data.id, label: data.name });
            });
            this.setState({ Permissions: newPermission });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  //  CREATE GROUP HANDEL
  submitHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "Permissions":
          break;
        case "name":
          formData.append(`${key}`, value);
          break;
        case "level":
          formData.append(`${key}`, value);
          break;
        case "permissions_id_list":
          if (this.state.permissions_id_list != "") {
            let permissionData = this.state.permissions_id_list;

            let val1 = permissionData.map((data) => {
              return data.value;
            });

            let val = val1.toString();
            formData.append(`${key}`, val);
          }
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    createGroup(formData)
      .then((res) => {
        this.setState({
          name: "",
          permissions_id_list: "",
          level: "",
        });
        toast.success(`Group  ${res.data.name} create sucessfully!`);
      })
      .catch((err) => {
        toast.error("Group create failed");
        console.log(err);
      });
  };

  //  UPDATE GROUP HANDEL
  updateHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "Permissions":
          break;
        case "name":
          formData.append(`${key}`, value);
          break;
        case "level":
          formData.append(`${key}`, value);
          break;
        case "permissions_id_list":
          if (this.state.permissions_id_list != "") {
            let permissionData = this.state.permissions_id_list;

            let val1 = permissionData.map((data) => {
              return data.value;
            });

            let val = val1.toString();
            formData.append(`${key}`, val);
          }
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    updateGroup(this.props.data.id, formData)
      .then((res) => {
        toast.success(`Group  ${res.data.name} updated sucessfully!`);
      })
      .catch((err) => {
        toast.error("Group update failed");
        console.log(err);
      });
  };

  selectHandler = (event, value) => {
    this.setState({ permissions_id_list: event });
  };
  render() {
    return (
      <div>
        <ToastContainer
          autoClose={5000}
          hideProgressBar={false}
          position="top-center"
        />
        {this.state.AddAuthentication || this.props.isEdit ? (
          <>
            {!this.props.isEdit ? <h1>Create New Group</h1> : ""}
            <Form onSubmit={this.submitHandler}>
              <Form.Group>
                <Col>
                  <Form.Label>
                    Group Name: <span className="asterisk"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Channel Name here"
                    name="name"
                    value={this.state.name}
                    onChange={this.changeHandler}
                  />
                </Col>
                <br />
              </Form.Group>
              <Form.Row>
                <Form.Label>
                  Permissions: <span className="asterisk"> *</span>
                </Form.Label>
                <Select
                  isMulti
                  defaultValue
                  value={this.state.permissions_id_list}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      width: 300,
                    }),
                  }}
                  name="permissions_id_list"
                  onChange={this.selectHandler}
                  // isLoading={this.state.isShowLoading}
                  options={this.state.Permissions}
                />
                {/* <Col>
              <Form.Control
                as="select"
                name="permissions_id_list"
                value={this.state.permissions_id_list}
                onChange={this.changeHandler}
              >
                <option defaultValue value="">
                  Select Action
                </option>
                {this.state.Permissions.map((data, index) => (
                  <option key={index} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </Form.Control>
            </Col> */}
                <Col>
                  <Form.Label>
                    Level: <span className="asterisk"> *</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="level"
                    value={this.state.level}
                    onChange={this.changeHandler}
                  >
                    <option defaultValue value="">
                      Select Level
                    </option>
                    <option value={0}>{"Super Admin"}</option>
                    <option value={1}>{"Admin"}</option>
                    <option value={2}>{"Manager"}</option>
                    <option value={3}>{"Staff"}</option>
                  </Form.Control>
                </Col>
              </Form.Row>
              {this.props.isEdit ? (
                <Button onClick={this.updateHandler} variant="info">
                  Update
                </Button>
              ) : (
                <Button
                  type="submit"
                  disabled={this.state.pulishDisabled}
                  variant="info"
                >
                  Publish
                </Button>
              )}
            </Form>
          </>
        ) : (
          <NotAuthenticatedMsg />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  GroupPermission: state.User.permission,
});

export default connect(mapStateToProps)(CreateGroup);
