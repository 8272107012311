import React, { Component } from 'react'
import { Form, Button, Figure } from 'react-bootstrap'
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CreateNotification } from '../../../services/Notification';

class Custom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: "",
            body: "",
            icon: ""
        }
    }


    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }


    submitHandler = (e) => {
        e.preventDefault()

        this.setState({ isLoading: true })
        let formData = new FormData();
        for (let [key, value] of Object.entries(this.state)) {
            switch (key) {
                case 'show_slug':
                    break;
                default:
                    formData.append(`${key}`, value)
            }
        }
        for (var [key, value] of formData.entries()) {
            if (value === '' || value === undefined || value === {}) {
                this.setState({ isLoading: false })
                return toast.error(`${key} is required!`)
            }
        }
        CreateNotification(formData)
            .then(response => {
                this.setState({ isLoading: false })
                console.log("noti", response.data)
                toast.success(`Notification  is created successfully.`)
            })
            .catch(error => {
                this.setState({ isLoading: false })
                console.log(error.response)
                if (error.response.status === 400) {
                    toast.error(JSON.stringify(error.response.data))
                    this.setState({ isLoading: false })
                }
                console.log(error)
            })

    }


    render() {
        const { title, body, icon } = this.state
        return (
            <div>
                <ToastContainer
                    autoClose={5000}
                    hideProgressBar={false}
                    position="top-center" />

                <h2>Send New Notification</h2>
                <Form onSubmit={this.submitHandler}>
                    <Form.Group>
                        <Form.Label>Title: <span className="asterisk"> *</span></Form.Label>
                        <Form.Control type="text" placeholder="Enter title here" name="title" value={title} onChange={this.changeHandler} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Body: <span className="asterisk"> *</span></Form.Label>
                        <Form.Control type="text" placeholder="Enter body here" name="body" value={body} onChange={this.changeHandler} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Icon: <span className="asterisk"> *</span></Form.Label>
                        <Form.Control type="url" placeholder="Enter Url here" name="icon" value={icon} onChange={this.changeHandler} />
                    </Form.Group>

                    {!this.state.isLoading ? <Button type="submit" variant="info">Publish</Button> : <Loader type="ThreeDots" color="#eb1163" height={100} width={50} />}
                </Form>
            </div>
        )
    }
}

export default Custom
