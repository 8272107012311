import http from '../http-common'
import auth from './authentication'

const baseURL = "https://staging.samortech.com/api";

const getAllPlatform = () => {
    return http.get(`${baseURL}/platforms/?limit=200`, );
};

const getPlatform = id => {
    return http.get(`${baseURL}/platforms/${id}/`, );
}

const createPlatform = data => {
    return http.post(`${baseURL}/platforms/`, {  data: data });
}

const updatePlatform = (id, data) => {
    return http.patch(`${baseURL}/platforms/${id}/`, {  data: data });
}

const removePlatform = id => {
    return http.delete(`${baseURL}/platforms/${id}`, );
}


export {
    getAllPlatform, getPlatform, createPlatform, updatePlatform, removePlatform, baseURL
}