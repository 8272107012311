import http from "../http-common";
const baseURL = "https://staging.samortech.com/api";

const getAllCmsUser = (page = 1) => {
  if (page === 1) {
    return http.get(`${baseURL}/cms-users/`);
  } else {
    return http.get(`${baseURL}/cms-users/?page=${page}`);
  }
  // return http.get(`${baseURL}/cms-users/`);
};

const createCmsUser = (data) => {
  return http.post(`${baseURL}/cms-users/`, data);
};

const updateCmsUser = (id, data) => {
  return http.patch(`${baseURL}/cms-users/${id}/`, data);
};
const GetCmsUserById = (id) => {
  return http.get(`${baseURL}/cms-users/${id}/`);
};
const removeCmsUser = (id) => {
  return http.delete(`${baseURL}/cms-users/${id}/`);
};

export {
  getAllCmsUser,
  createCmsUser,
  updateCmsUser,
  GetCmsUserById,
  removeCmsUser,
};
