import http from "../http-common";
import auth from "./authentication";

const baseURL = "https://staging.samortech.com/api";

const getAllCategory = () => {
  return http.get(`${baseURL}/category/?all=True`);
};

const getCategoryDropDowns = () => {
  return http.get(`${baseURL}/category/?all=True&only_select=True`);
};
const getAllCategoryPagination = (page = 1) => {
  return http.get(`${baseURL}/category/?page=${page}`);
};

const getCategory = (id) => {
  return http.get(`${baseURL}/category/${id}/`);
};

const createCategory = (data) => {
  return http.post(`${baseURL}/category/`, { data: data });
};

const updateCategory = (id, data) => {
  return http.patch(`${baseURL}/category/${id}/`, data);
};

const removeCategory = (id) => {
  return http.delete(`${baseURL}/category/${id}/`);
};

const removeAllCategory = () => {
  return http.delete(`${baseURL}/category`);
};

const findByTitleCategory = (title) => {
  return http.get(`${baseURL}/category?title=${title}`);
};
const categorySequnce = (data) => {
  let sequencedata = {
    categories: data,
  };
  return http.post(`${baseURL}/category/order_categories/`, sequencedata);
};

export {
  getAllCategory,
  getCategoryDropDowns,
  getAllCategoryPagination,
  getCategory,
  createCategory,
  updateCategory,
  removeCategory,
  removeAllCategory,
  findByTitleCategory,
  categorySequnce,
  baseURL,
};
