import http from "../http-common";
import auth from "./authentication";

const baseURL = "https://staging.samortech.com/api";

const getAllLandingPage = () => {
  return http.get(`${baseURL}/guest-landingpage/?all=True`, {
    auth: auth,
  });
};

const createLandingPage = (data) => {
  return http.post(`${baseURL}/guest-landingpage/`, data, {
    auth: auth,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const updateLandingPage = (id, data) => {
  return http.patch(`${baseURL}/guest-landingpage/${id}/`, data, {
    auth: auth,
  });
};

const removeLandingPage = (id) => {
  return http.delete(`${baseURL}/guest-landingpage/${id}/`, { auth: auth });
};

export {
  getAllLandingPage,
  removeLandingPage,
  updateLandingPage,
  createLandingPage,
};
