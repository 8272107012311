import http from "../http-common";
import auth from "./authentication";
const baseURL = "https://staging.samortech.com/api";

const removeEpisode = (id) => {
  return http.delete(`${baseURL}/episodes/${id}/`);
};

const sequenceEpisode = (id, data) => {
  let sequencedata = {
    episode_slugs: data,
  };
  return http.post(
    `${baseURL}/shows/${id}/update_episode_sequence/`,
    sequencedata
  );
};
const liveEpisodes = () => {
  return http.get(`${baseURL}/episodes/?is_live=True`);
};

export { removeEpisode, sequenceEpisode, liveEpisodes };
